<template>
  <v-text-field
    :value="value"
    @input="returnValue($event)"
    :label="label"
    color="#0078FF"
    outlined
    dense
    :disabled="disabled"
    clearable
    style="border-radius: 6px"
    prepend-inner-icon="mdi-magnify"
    type="text"
    ref="input"
    hide-details
  ></v-text-field>
</template>
<script>
export default {
  props: {
    label: { type: String, default: "" },
    value: {},
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({}),
  methods: {
    returnValue(item) {
      this.$emit("input", item ? item.trim() : item);
    },
  },
  computed: {},
  mounted() {},
  created() {},
  watch: {},
};
</script>
<style scoped lang="scss">
</style>
