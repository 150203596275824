import Vue from "vue";

Vue.mixin({
  methods: {
    createImageURL(val) {
      if (val && val.includes("http")) {
        return val;
      } else {
        return this.$store.getters.baseURL + val;
      }
    },
    validateEmail(mail) {
      const reg = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
      return reg.test(mail);
    },
    declinationOfWords(value, words) {
      value = Math.abs(value) % 100;
      var num = value % 10;
      if (value > 10 && value < 20) return words[2];
      if (num > 1 && num < 5) return words[1];
      if (num == 1) return words[0];
      return words[2];
    },
    getInformationImage(image) {
      return new Promise((resolve, reject) => {
        try {
          let img = new Image();
          img.src = URL.createObjectURL(image);
          img.onload = function () {
            const newObj = {
              width: img.width,
              height: img.height,
            };
            resolve(newObj);
          };
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
