import { render, staticRenderFns } from "./Dialog.vue?vue&type=template&id=f9dfe554&scoped=true"
import script from "./Dialog.vue?vue&type=script&lang=js"
export * from "./Dialog.vue?vue&type=script&lang=js"
import style0 from "./Dialog.vue?vue&type=style&index=0&id=f9dfe554&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9dfe554",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VForm,VIcon})
