<template>
  <div class="setting-container">
    <v-tabs class="container-tabs" background-color="var(--app-grey-1)">
      <v-tab href="#tab-1">Настройки</v-tab>
      <v-tab href="#tab-2">Техподдержка</v-tab>
      <v-tab href="#tab-3">Персонализация</v-tab>
      <v-tab-item value="tab-1">
        <v-divider></v-divider>
        <div class="switch-card">
          <v-switch
            @change="settingMobileUpdate(false)"
            label="Показать авторизацию через Apple"
            v-model="switchData.isVisibleApple"
          ></v-switch>
          <v-switch
            @change="settingMobileUpdate(false)"
            label="Показать авторизацию через ВКонтакте"
            v-model="switchData.isVisibleVk"
          ></v-switch>
          <v-switch
            @change="settingMobileUpdate(false)"
            label="Показать авторизацию через Google"
            v-model="switchData.isVisibleGoogle"
          ></v-switch>
          <v-switch
            @change="settingMobileUpdate(false)"
            label="Показать авторизацию через Facebook"
            v-model="switchData.isVisibleFacebook"
          ></v-switch>
          <v-switch
            @change="settingMobileUpdate(false)"
            label="Показать авторизацию через E-mail"
            v-model="switchData.isVisibleEmail"
          ></v-switch>
          <v-switch
            @change="settingMobileUpdate(false)"
            label="Показать авторизацию по номеру телефона"
            v-model="switchData.isVisiblePhone"
          ></v-switch>
          <v-switch
            @change="settingMobileUpdate(false)"
            label="Показать авторизацию по звонку"
            v-model="switchData.isVisibleFlashcall"
          ></v-switch>
          <v-text-field
            style="max-width: 300px"
            outlined
            label="Время жизни токена авторизации (в минутах)"
            v-model="authorizationTokenLifetime"
            class="mt-4"
          ></v-text-field>
          <v-btn
            depressed
            class="button-blue"
            @click="settingMobileUpdate(true)"
            >Сохранить</v-btn
          >
        </div>
      </v-tab-item>
      <v-tab-item value="tab-2"
        ><v-divider></v-divider>
        <div class="switch-card">
          <div class="container-items"></div>
          <div style="width: 300px">
            <v-text-field
              outlined
              label="Email"
              v-model="switchData.emailSupport"
            ></v-text-field>
          </div>
          <div>
            <v-btn
              depressed
              class="button-blue"
              @click="settingMobileUpdate(true)"
              >Сохранить</v-btn
            >
          </div>
        </div>
      </v-tab-item>
      <v-tab-item value="tab-3"
        ><v-divider></v-divider>
        <div class="container-items">
          <div class="items-block">
            <div
              class="block-input"
              v-for="(item, index) in settingsPers"
              :key="index"
            >
              <div v-if="item.type == 'file'" class="d-flex">
                <FileInput
                  class="mb-4"
                  hideDetails
                  :label="item.name"
                  v-model="item.content"
                ></FileInput>
                <div
                  class="file-input-download"
                  :style="
                    typeof item.content == 'string' ? ' cursor: pointer;' : ''
                  "
                >
                  <a
                    v-if="typeof item.content == 'string'"
                    :href="item.content"
                    target="_blank"
                  >
                    <v-icon color="var(--app-blue)">mdi-download</v-icon>
                  </a>

                  <v-icon
                    v-else-if="
                      typeof item.content == 'object' && item.content != null
                    "
                    >mdi-download</v-icon
                  >
                </div>
              </div>
              <div v-if="item.type == 'boolean'">
                <SwitchInput :label="item.name" v-model="item.content">
                </SwitchInput>
              </div>
              <div v-if="item.type == 'string'">
                <TextInput
                  class="mb-4"
                  hideDetails
                  :label="item.name"
                  v-model="item.content"
                >
                </TextInput>
              </div>
            </div>
          </div>
          <div class="items-button-block">
            <v-btn
              :loading="sendItems"
              @click="savePersonofication"
              depressed
              class="button-blue"
              >Сохранить</v-btn
            >
          </div>
        </div>
        <v-progress-linear
          :active="activeProgress"
          color="blue"
          style="max-height: 2px"
          v-model="progress"
        ></v-progress-linear>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import axios from "axios";
import FileInput from "@/components/main/inputs/FileInput.vue";
import SwitchInput from "@/components/main/inputs/SwitchInput.vue";
import TextInput from "@/components/main/inputs/TextInput.vue";

export default {
  components: { FileInput, SwitchInput, TextInput },
  data: () => ({
    test: null,
    pdfFile: false,
    infoImage: "",
    videoNotUploaded: true,
    settingsPers: [],

    progress: 0,
    activeProgress: false,
    authorizationTokenLifetime: 0,
    switchData: {
      isVisibleApple: false,
      isVisibleVk: false,
      isVisibleGoogle: false,
      isVisibleFacebook: false,
      isVisibleEmail: false,
      isVisiblePhone: false,
      isVisibleFlashcall:false,
    },
    sendItems: false,
  }),

  computed: {},
  created() {
    this.getSettingMobile();
    this.getSettingsPersonofication();
  },

  methods: {
    async saveFile(file) {
      let url;
      try {
        let formData = new FormData();
        formData.append("file", file);
        await this.$axios
          .post(
            this.$store.getters.apiAdminPanelV4 + "/personofication/file ",
            formData,
            {
              headers: {
                authorization: this.$store.getters.adminPanelHeaderAuth,
              },
            }
          )
          .then((res) => {
            url = res.data;
          })
          .catch((err) => {
            this.$store.commit("errorSet", true);
            this.$store.commit("errorTextSet", err);
          });
      } catch (e) {
        this.$store.commit("errorSet", true);
        this.$store.commit("errorTextSet", "Ошибка загрузки картинки");
      }
      return url;
    },
    async savePersonofication() {
      this.sendItems = true;
      for (let item of this.settingsPers) {
        if (
          item.type == "file" &&
          item.content &&
          typeof item.content == "object"
        ) {
          item.content = await this.saveFile(item.content);
        }
      }
      this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/personofication",
          {
            personofication: this.settingsPers,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.sendItems = false;
          this.getSettingsPersonofication();
          this.$store.commit("successSet", true);
          this.$store.commit("successTextSet", "Изменения успешно сохранены");
        })
        .catch((err) => {
          this.sendItems = false;
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    async getSettingsPersonofication() {
      await axios
        .get(this.$store.getters.apiAdminPanelV4 + "/personofication", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.settingsPers = res.data;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },

    getSettingMobile() {
      axios
        .get(this.$store.getters.apiAdminPanelV4 + "/settings", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.authorizationTokenLifetime =
            res.data.authorizationTokenLifetime / 60000;

          this.switchData = res.data;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    settingMobileUpdate(boolean) {
      this.switchData.authorizationTokenLifetime =
        this.authorizationTokenLifetime * 60000;
      axios
        .put(
          this.$store.getters.apiAdminPanelV4 + "/settings",
          this.switchData,
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          if (boolean) {
            this.$store.commit("successSet", true);
            this.$store.commit(
              "successTextSet",
              "Измененения успешно сохранены"
            );
            this.getSettingMobile();
          }
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.setting-container {
  padding: 40px 64px 0 64px;
  height: 100%;
  .container-tabs {
    height: 100%;
  }
}
.switch-card {
  background-color: var(--app-grey-1);
  padding: 24px 12px 0px 12px;
  height: 100%;
  overflow: auto;
}
.container-items {
  display: flex;
  height: 100%;
  padding: 24px 12px 0px 12px;
  justify-content: space-between;
  flex-direction: column;
  .items-block {
    padding: 12px 0px;
    height: calc(100vh - 220px);
    overflow: auto;
    .block-input {
      width: 50%;
      .file-input-download {
        margin-left: 10px;
        background: white;
        width: 55px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        cursor: pointer;
      }
    }
  }
  .items-button-block {
    padding: 12px 0px;
  }
}
</style>