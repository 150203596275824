<template>
  <v-text-field
    :disabled="disabled"
    :value="value"
    @input="$emit('input', $event)"
    :label="label"
    :rules="checkRules"
    color="#0078FF"
    outlined
    :clearable="clearable"
    :error-messages="errorMessages"
    :maxlength="maxLength"
    :placeholder="placeholder"
    :prepend-inner-icon="prependIcon"
    :type="type"
    :min="0"
    :max="max"
    ref="input"
    :hide-details="hideDetails"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    clearable: { type: Boolean, default: false },
    errorMessages: { type: String, default: "" },
    label: { type: String, default: "" },
    value: {},
    valid: { type: Boolean, default: false },
    type: { type: String, default: "text" },
    max: { type: Number },
    maxLength: { type: Number },
    prependIcon: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
  },
  data: () => ({}),
  methods: {},
  computed: {
    checkRules() {
      if (this.valid) {
        if (this.type == "number") {
          if (this.max) {
            return [
              (v) => !!v || "Обязательно к заполнению",
              (v) => (v && v >= 0) || "Минимальное значение 0",
              (v) =>
                (v && v <= this.max) || `Максимальное значение ${this.max}`,
            ];
          }
          return [(v) => !!v || "Обязательно к заполнению"];
        } else if (this.type == "phone") {
          return [
            (value) => !!value || "Обязательно к заполнению",
            (value) => value.length === 12 || "Введите корректный номер",
          ];
        } else if (this.type == "mail") {
          return [
            (value) => !!value || "Обязательно к заполнению",
            (value) =>
              this.validateEmail(value) ||
              "Почта должна содержать @domen.ru например pochta@mail.ru",
          ];
        }
        return [(v) => !!v || "Обязательно к заполнению"];
      }
      return [];
    },
  },
  mounted() {},
  created() {},
  watch: {},
};
</script>
<style lang="scss">
</style>
