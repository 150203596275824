<template>
  <div
    class="table-main-container"
    :id="resizingKey ? `${resizingKey}container` : ``"
  >
    <div
      class="table-container"
      @scroll="scrollMethod"
      :style="`height: ${height};`"
    >
      <table class="table" :id="resizingKey ? resizingKey : ''">
        <thead
          class="table-header"
          :class="headerFixed ? 'table-header-fixed' : ''"
          :style="headerHidden ? 'display: none' : ''"
        >
          <tr>
            <th
              v-for="(headItem, index) in headers"
              :key="index"
              :style="`width: ${headItem.width} !important;`"
            >
              <div
                class="th-value"
                @click="sortTable(headItem)"
                :style="alignStyle(headItem)"
              >
                <div
                  class="th-sort"
                  v-if="headItem.value != 'draggable' && headItem.sortable"
                >
                  <svg
                    v-if="sortNoneSVG(headItem)"
                    width="14px"
                    height="14px"
                    viewBox="0 0 14 14"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="Icon/14/Arrow/Up_n_Down/Deseble"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline
                        id="Path-3"
                        stroke="#8D8D8D"
                        stroke-width="0.75"
                        points="4 9 7 12 10 9"
                      ></polyline>
                      <polyline
                        id="Path-3-Copy"
                        stroke="#8D8D8D"
                        stroke-width="0.75"
                        transform="translate(7.000000, 3.500000) scale(1, -1) translate(-7.000000, -3.500000) "
                        points="4 2 7 5 10 2"
                      ></polyline>
                    </g>
                  </svg>
                  <svg
                    v-if="sortDownSVG(headItem)"
                    width="14px"
                    height="14px"
                    viewBox="0 0 14 14"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="Icon/14/Arrow/Up_n_Down/ActiveDown"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline
                        id="Path-3"
                        stroke="#007AFF"
                        stroke-width="0.75"
                        points="4 9 7 12 10 9"
                      ></polyline>
                      <polyline
                        id="Path-3-Copy"
                        stroke="#8D8D8D"
                        stroke-width="0.75"
                        transform="translate(7.000000, 3.500000) scale(1, -1) translate(-7.000000, -3.500000) "
                        points="4 2 7 5 10 2"
                      ></polyline>
                    </g>
                  </svg>
                  <svg
                    v-if="sortUpSVG(headItem)"
                    width="14px"
                    height="14px"
                    viewBox="0 0 14 14"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      id="Icon/14/Arrow/Up_n_Down/ActiveUp"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline
                        id="Path-3"
                        stroke="#8D8D8D"
                        stroke-width="0.75"
                        points="4 9 7 12 10 9"
                      ></polyline>
                      <polyline
                        id="Path-3-Copy"
                        stroke="#007AFF"
                        stroke-width="0.75"
                        transform="translate(7.000000, 3.500000) scale(1, -1) translate(-7.000000, -3.500000) "
                        points="4 2 7 5 10 2"
                      ></polyline>
                    </g>
                  </svg>
                </div>
                <div>
                  <slot :name="`header.${headItem.value}`" :item="headItem">
                    <v-icon color="#0078FF" v-if="headItem.icon">{{
                      headItem.icon
                    }}</v-icon>
                    {{ headItem.text }}
                  </slot>
                </div>
              </div>
            </th>
          </tr>
        </thead>

        <draggable
          v-if="draggable"
          :list="itemsAll"
          tag="tbody"
          @end="endDrag"
          :move="checkMove"
          :style="moveItems ? 'pointer-events: none;' : ''"
          class="table-body"
        >
          <tr
            v-for="(i, index) in itemsAll"
            :key="index"
            @contextmenu="$emit('contextmenu', $event, i)"
          >
            <td
              v-for="(item, ind) in listFind(i)"
              :key="ind"
              @click="clickItem(i, item.name)"
            >
              <slot :name="`item.${item.name}`" :item="i" :index="index">
                <template v-if="item.name == 'draggable'">
                  <v-icon
                    size="24"
                    color="var(--app-separators)"
                    style="cursor: pointer"
                    >mdi-menu</v-icon
                  >
                </template>
                {{ item.value }}
              </slot>
            </td>
          </tr>
        </draggable>
        <tbody v-else class="table-body">
          <tr
            v-for="(i, index) in itemsAll"
            :key="index"
            @contextmenu="$emit('contextmenu', $event, i)"
          >
            <td
              v-for="(item, ind) in listFind(i)"
              :key="ind"
              @click="clickItem(i, item.name)"
            >
              <div class="d-flex" :style="alignStyle(headers[ind])">
                <slot :name="`item.${item.name}`" :item="i" :index="index">
                  {{ item.value }}
                </slot>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="footerProps.itemsLength"
      class="footer-container"
      :style="dialogAttach ? 'z-index: 1002' : ''"
    >
      <div class="footer-container-select">
        <v-select
          style="width: 60px; font-size: 12px"
          :items="footerProps.itemsPerPageOptions"
          v-model="footerProps.itemsPerPage"
          dense
          @change="changePerPage"
        ></v-select>
      </div>
      <div class="footer-container-text">
        {{ pageItems }} из {{ footerProps.itemsLength }}
      </div>
      <div class="footer-container-buttons">
        <v-btn :disabled="footerProps.page == 1" icon @click="changePage(true)">
          <v-icon> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn :disabled="disabledButton" icon @click="changePage(false)">
          <v-icon> mdi-chevron-right </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    headers: {
      type: Array,
      default() {
        return [];
      },
    },
    height: {
      type: String,
      default: "100%",
    },
    headerFixed: {
      type: Boolean,
      default: false,
    },
    headerHidden: {
      type: Boolean,
      default: false,
    },
    resizingTable: {
      type: Boolean,
      default: false,
    },
    resizingKey: {
      type: String,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    noClickSlots: {
      type: Array,
      default() {
        return [];
      },
    },
    footerProps: {
      type: Object,
      default() {
        return {
          itemsPerPageOptions: [10, 25, 50],
          itemsLength: 0,
          page: 1,
          itemsPerPage: 10,
        };
      },
    },
    dialogAttach: { type: Boolean, default: false },
  },
  data: () => ({
    slots: [],
    selectedDrag: null,
    moveItems: false,
    sortItems: [],
    itemsAll: [],
  }),
  methods: {
    scrollMethod(e) {
      const scrollTop = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight - e.target.clientHeight;

      if (scrollTop >= scrollHeight - 1000) {
        this.sliceConcat();
      }
      this.$emit("scrollMethod", e);
    },
    sliceConcat() {
      let arrSliced = this.items.slice(
        this.itemsAll.length,
        this.itemsAll.length + 50
      );
      this.itemsAll = this.itemsAll.concat(arrSliced);
    },
    fiftyItems() {
      if (this.items.length < 50) {
        this.itemsAll = this.items;
      } else {
        for (let i = 0; i < 50; i++) {
          const element = this.items[i];
          this.itemsAll.push(element);
        }
      }
    },
    alignStyle(item) {
      return `justify-content: ${item.align};`;
    },
    sortNoneSVG(item) {
      const head = this.sortItems.find((i) => i.value == item.value);
      if (head) {
        if (head.sortState == "none") {
          return true;
        }
        return false;
      }
      return false;
    },
    sortDownSVG(item) {
      const head = this.sortItems.find((i) => i.value == item.value);
      if (head) {
        if (head.sortState == "down") {
          return true;
        }
        return false;
      }
      return false;
    },
    sortUpSVG(item) {
      const head = this.sortItems.find((i) => i.value == item.value);
      if (head) {
        if (head.sortState == "up") {
          return true;
        }
        return false;
      }
      return false;
    },
    sortTable(item) {
      const head = this.sortItems.find((i) => i.value == item.value);
      if (head) {
        this.sortItems.map((i) => {
          if (i.value != head.value) {
            i.sortState = "none";
          }
        });
        if (head.sortState == "none") {
          this.items.sort((a, b) => (a[item.value] > b[item.value] ? -1 : 1));
          this.sortItems.map((i) => {
            if (i.value == item.value) {
              i.sortState = "down";
            }
          });
        } else if (head.sortState == "down") {
          this.items.sort((a, b) => (a[item.value] < b[item.value] ? -1 : 1));
          this.sortItems.map((i) => {
            if (i.value == item.value) {
              i.sortState = "up";
            }
          });
        } else if (head.sortState == "up") {
          this.sortItems.map((i) => {
            if (i.value == item.value) {
              i.sortState = "none";
            }
          });
        }
      }
    },
    listFind(item) {
      const list = [];
      this.headers.forEach((element) => {
        list.push({ name: element.value, value: item[element.value] });
      });
      return list;
    },
    checkMove(evt) {
      this.selectedDrag = evt.draggedContext.element;
    },
    endDrag(evt) {
      this.moveItems = true;
      this.$emit("click:draggable", evt.newIndex + 1, this.selectedDrag);
      this.$nextTick(() => {
        this.moveItems = false;
      });
    },
    changePerPage(e) {
      const data = {
        itemsPerPage: e,
        page: this.footerProps.page,
      };
      this.sortItems.map((i) => {
        i.sortState = "none";
      });
      this.$emit("pagination", data);
    },
    changePage(left) {
      let newPage;
      if (left) {
        if (this.footerProps.page > 1) {
          newPage = this.footerProps.page - 1;
        } else {
          newPage = this.footerProps.page;
        }
      } else {
        newPage = this.footerProps.page + 1;
      }
      const data = {
        itemsPerPage: this.footerProps.itemsPerPage,
        page: newPage,
      };
      this.sortItems.map((i) => {
        i.sortState = "none";
      });
      this.$emit("pagination", data);
    },
    clickItem(item, name) {
      if (this.noClickSlots.length < 1 || !this.noClickSlots.includes(name)) {
        this.$emit("click:row", item);
      }
    },

    createDiv() {
      const div = document.createElement("div");
      div.style.top = 0;
      div.style.right = 0;
      div.style.width = "5px";
      div.style.position = "absolute";
      div.style.cursor = "col-resize";
      div.style.userSelect = "none";
      div.style.height = "100%";
      return div;
    },

    resizableGrid(table) {
      const cols = table.getElementsByTagName("th");
      const tableHeight = table.offsetHeight;
      for (let i = 0; i < cols.length; i++) {
        const div = this.createDiv(tableHeight);
        cols[i].appendChild(div);
        cols[i].style.position = "relative";
        this.setListeners(div);
      }
      const thead = table.getElementsByTagName("thead");
      if (thead[0]) {
        thead[0].addEventListener("mouseup", () => {
          this.saveWidthCol();
        });
      }
    },

    setListeners(div) {
      let pageX,
        curCol,
        // nxtCol,
        curColWidth,
        // nxtColWidth,
        tableWidth,
        containerWidth;
      div.addEventListener("mousedown", (e) => {
        curCol = e.target.parentElement;
        tableWidth = document.getElementById(this.resizingKey).offsetWidth;
        containerWidth = document.getElementById(
          `${this.resizingKey}container`
        ).offsetWidth;

        // nxtCol = curCol.nextElementSibling;
        pageX = e.pageX;
        curColWidth = curCol.offsetWidth;
        // if (nxtCol) nxtColWidth = nxtCol.offsetWidth;
      });
      div.addEventListener("mouseover", (e) => {
        e.target.style.borderRight = "3px solid rgba(0, 0, 0, 0.15)";
      });
      div.addEventListener("mouseout", (e) => {
        e.target.style.borderRight = "";
      });
      document.addEventListener("mousemove", (e) => {
        if (curCol) {
          const diffX = e.pageX - pageX;
          if (tableWidth + diffX >= containerWidth) {
            document.getElementById(this.resizingKey).style.width =
              tableWidth + diffX + "px";
          }

          // if (diffX > 0) {
          //   table.style.width = tableWidth + diffX;
          // }

          // if (nxtCol) nxtCol.style.width = nxtColWidth - diffX + "px";
          curCol.style.width = curColWidth + diffX + "px";
        }
      });
      document.addEventListener("mouseup", () => {
        curCol = null;
        // nxtCol = null;
        pageX = null;
        // nxtColWidth = null;
        curColWidth = null;
      });
    },
    findSortItems() {
      this.headers.forEach((element) => {
        if (element.sortable) {
          this.sortItems.push({ ...element, sortState: "none" });
        }
      });
    },
    saveWidthCol() {
      if (this.resizingKey) {
        const tableValues = JSON.parse(
          localStorage.getItem("tableSizingWidth")
        );
        const neededValues = tableValues.find((i) => i.key == this.resizingKey);

        const table = document.getElementById(this.resizingKey);
        const rows = table.getElementsByTagName("th");
        if (neededValues) {
          let arr = [];
          for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            arr.push(element.offsetWidth);
          }
          neededValues.tableItems = arr;
          tableValues.map((i) => {
            if (i.key == this.resizingKey) {
              i = neededValues;
            }
          });
        } else {
          let arr = [];
          for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            arr.push(element.offsetWidth);
          }
          const newObj = {
            key: this.resizingKey,
            tableItems: arr,
          };
          tableValues.push(newObj);
        }
        localStorage.setItem("tableSizingWidth", JSON.stringify(tableValues));
      }
    },
  },
  computed: {
    pageItems() {
      const all = this.footerProps.itemsPerPage * this.footerProps.page;
      const start = all - this.footerProps.itemsPerPage;

      return `${start + 1}-${
        all > this.footerProps.itemsLength ? this.footerProps.itemsLength : all
      }`;
    },
    disabledButton() {
      const all = this.footerProps.itemsPerPage * this.footerProps.page;
      return all >= this.footerProps.itemsLength;
    },
  },
  created() {
    if (this.draggable) {
      const newHead = {
        text: "",
        value: "draggable",
        width: "100px",
        align: "center",
      };
      if (!this.headers.find((i) => i.value === "draggable")) {
        this.headers.unshift(newHead);
      }
    }
    if (!localStorage.getItem("tableSizingWidth")) {
      localStorage.setItem("tableSizingWidth", JSON.stringify([]));
    }
    this.findSortItems();
     this.fiftyItems();
  },
  mounted() {
    if (!this.headerHidden) {
      if (this.resizingKey) {
        this.resizableGrid(document.getElementById(this.resizingKey));
      }
      if (localStorage.getItem("tableSizingWidth")) {
        const tableValues = JSON.parse(
          localStorage.getItem("tableSizingWidth")
        );
        const neededValues = tableValues.find((i) => i.key == this.resizingKey);
        if (neededValues) {
          const table = document.getElementById(this.resizingKey);
          const containerWidth = document.getElementById(
            `${this.resizingKey}container`
          ).offsetWidth;
          const tableItemsLength = neededValues.tableItems.reduce(
            (a, b) => a + b,
            0
          );
          if (tableItemsLength > containerWidth) {
            table.style.width = tableItemsLength + "px";
          }

          const rows = table.getElementsByTagName("th");
          for (let index = 0; index < rows.length; index++) {
            const element = rows[index];
            element.style.width = `${neededValues.tableItems[index]}px;`;
          }
        }
      }
    }
  },

  watch: {
    items() {
      this.fiftyItems();
    },
  },
};
</script>
<style lang="scss" scoped>
.table-main-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: var(--app-grey-1);
  height: 100%;
}
.table-container {
  width: 100%;
  overflow: auto;
  table {
    width: 100%;
    border-spacing: 0px;
    position: relative;
    .table-header {
      tr {
        th {
          text-align: start;
          border-bottom: 1px solid var(--app-separators);
          padding: 16px;
          font-size: 12px;
          color: var(--app-grey-3);
          user-select: none;

          .th-value {
            display: flex;
            align-items: center;
            cursor: pointer;
            .th-sort {
              display: flex;
              flex-direction: column;
              margin-right: 8px;
            }
          }
        }
      }
      // tr {
      //   th:first-child {
      //     padding: 16px 10px 16px 32px;
      //   }
      // }
      // tr {
      //   th:last-child {
      //     padding: 16px 32px 16px 10px;
      //   }
      // }
    }
    .table-body {
      tr {
        cursor: pointer;
        // background: #f7f7fa;
        td {
          padding: 16px;
          font-size: 12px;
          border-bottom: 1px solid var(--app-separators);
        }
      }
      // tr:last-child {
      //   td {
      //     border-bottom: 1px solid var(--app-separators);
      //   }
      // }
      tr:hover {
        background: rgba(0, 0, 0, 0.05);
      }
      // tr {
      //   td:first-child {
      //     padding: 16px 10px 16px 32px;
      //   }
      // }
      // tr {
      //   td:last-child {
      //     padding: 16px 32px 16px 10px;
      //   }
      // }
    }

    .table-header-fixed {
      position: sticky;
      top: 0;
      z-index: 2;
      background: var(--app-grey-1);
    }
  }
}
.footer-container {
  padding: 0 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  background: #f7f7fa;
  .footer-container-select {
    padding-top: 10px;
  }
  .footer-container-text {
    font-size: 12px;
    min-width: 100px;
    margin-left: 50px;
    // padding-top: 12px;
  }
  .footer-container-buttons {
    // padding-top: 6px;
    margin-left: 30px;
    margin-right: 20px;
    button:first-child {
      margin-right: 24px;
    }
  }
}
</style>
