<template>
  <div class="author">
    <v-snackbar :top="true" v-model="snackbar.isOpen">
      {{ snackbar.text }}
      <v-btn @click="snackbar.isOpen = false" color="red" text> Close </v-btn>
    </v-snackbar>
    <v-snackbar
      color="error"
      top
      :timeout="0"
      v-model="directoriesSnackbar.isOpen"
    >
      Для добавления курса необходимо задать {{ directoriesSnackbar.text }} в
      разделе Справочники.
      <v-btn @click="directoriesSnackbar.isOpen = false" dark flat>
        Close
      </v-btn>
    </v-snackbar>
    <v-card-title>
      <v-btn :to="{ name: 'stocks' }" text> Назад</v-btn>
      <v-spacer></v-spacer>
      <v-dialog max-width="500px" persistent v-model="dialogPromocode">
        <template v-slot:activator="{ on }">
          <v-btn
            @click="openFormPromocode"
            class="ml-6 button-action-none-color"
            dark
            style="width: 200px !important"
            v-on="on"
            >Редактировать промокод
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="title ml-3">Данные ментора</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-form ref="promocodeForm" lazy-validation>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="11"
                    style="height: 65px; margin-left: 35px; padding-right: 8px"
                  >
                    <v-text-field
                      :rules="rules"
                      label="Название"
                      v-model="promocodeCopy.name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="11"
                    style="height: 65px; margin-left: 35px; padding-right: 8px"
                  >
                    <v-text-field
                      type="number"
                      :rules="rules"
                      label="Код"
                      v-model="promocodeCopy.code"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="11"
                    style="height: 65px; margin-left: 35px; padding-right: 8px"
                  >
                    <v-text-field
                      type="number"
                      :rules="rules"
                      label="Процент"
                      v-model="promocodeCopy.percent"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="11"
                    style="height: 65px; margin-left: 35px; padding-right: 8px"
                  >
                    <v-text-field
                      type="number"
                      :rules="rules"
                      label="Количество"
                      v-model="promocodeCopy.quantity"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="11"
                    style="height: 65px; margin-left: 35px; padding-right: 8px"
                  >
                    <v-dialog
                      ref="dialog"
                      v-model="modalPicker"
                      :return-value.sync="promocodeCopy.toDateForm"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="promocodeCopy.toDateForm"
                          label="Срок действия"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="promocodeCopy.toDateForm"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modalPicker = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(promocodeCopy.toDateForm)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
          <v-card-actions class="pb-10">
            <v-btn
              @click="cancelPromocodeBtn"
              class="ml-6 caption button-cancel"
              >Отмена</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn @click="savePromocodeBtn" class="mr-6 caption button-green"
              >Сохранить</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-btn
        @click="blockPromocodeBtn(false)"
        class="ml-6 button-cancel"
        style="width: 200px !important"
        v-if="promocode.isUse"
        >Отключить промокод
      </v-btn>
      <v-btn
        @click="blockPromocodeBtn(true)"
        class="ml-6 button-action"
        style="width: 200px !important"
        v-if="!promocode.isUse"
        >Включить промокод
      </v-btn>
    </v-card-title>

    <table class="mt-12 ml-6 mb-12" style="width: 100%">
      <v-row>
        <v-col class="table-first-cell">ID:</v-col>
        <v-col class="pl-5">{{ promocode.id }}</v-col>
      </v-row>
      <v-row>
        <v-col class="table-first-cell">Название:</v-col>
        <v-col class="pl-5">{{ promocode.name }}</v-col>
      </v-row>
      <v-row>
        <v-col class="table-first-cell">Код:</v-col>
        <v-col class="pl-5">{{ promocode.code }}</v-col>
      </v-row>
      <v-row>
        <v-col class="table-first-cell">Процент:</v-col>
        <v-col class="pl-5">{{ promocode.percent }}</v-col>
      </v-row>
      <v-row>
        <v-col class="table-first-cell">Количество:</v-col>
        <v-col class="pl-5">{{ promocode.quantity }}</v-col>
      </v-row>
      <v-row>
        <v-col class="table-first-cell">Количество дней:</v-col>
        <v-col class="pl-5">{{
          promocode.toDate | moment("DD.MM.YYYY")
        }}</v-col>
      </v-row>
      <v-row>
        <v-col class="table-first-cell">Дата создания:</v-col>
        <v-col class="pl-5">{{
          promocode.dateCreated | moment("HH:mm DD.MM.YYYY")
        }}</v-col>
      </v-row>
    </table>

    <v-card>
      <v-card-title>
        Курсы
        <v-spacer></v-spacer>

        <v-dialog max-width="500px" persistent v-model="dialogCourse">
          <template v-slot:activator="{ on }">
            <v-btn
              depressed
              class="button-action"
              dark
              v-on="on"
              @click="directoriesSnackbar.isOpen = directoriesSnackbar.toOpen"
              >Добавить курс
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="title ml-3">Добавление курса</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-form lazy-validation ref="course">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="11"
                      style="
                        height: 65px;
                        margin-left: 35px;
                        padding-right: 8px;
                      "
                    >
                      <v-select
                        :items="authors"
                        item-text="fullName"
                        item-value="authorId"
                        @change="getCourses"
                        v-model="authorId"
                        :rules="rules"
                        label="Авторы"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="11"
                      style="
                        height: 65px;
                        margin-left: 35px;
                        padding-right: 8px;
                      "
                    >
                      <v-select
                        :items="courses"
                        item-text="name"
                        item-value="courseId"
                        v-model="courseId"
                        :rules="rules"
                        label="Курсы"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-10">
                <v-btn @click="cancelCourseBtn" class="ml-6 button-cancel"
                  >Отмена</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn @click="saveCourseBtn" class="mr-6 button-green"
                  >Добавить</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25],
        }"
        :headers="courseHeaders"
        :items="promocodeCourses"
        :items-per-page="courseOptionTable.itemsPerPage"
        :page="courseOptionTable.page"
        :server-items-length="courseOptionTable.serverItemsLength"
        @pagination="courseTableChange"
        class="elevation-1"
      >
        <template v-slot:no-data>
          <v-btn @click="getPromocodeCourses" color="primary">Обновить</v-btn>
        </template>
        <template v-slot:item.dateCreated="{ item }">
          {{ item.dateCreated | moment("DD.MM.YYYY") }}
        </template>
        <template v-slot:item.course="{ item }">
          {{ item.course.name }}
        </template>
        <template v-slot:item.author="{ item }">
          {{ item.course.author.fullName }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon @click="onClickRow(item)" class="mr-2" small>
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "promocode",
  data: () => ({
    dialogPromocode: false,
    promocode: {
      id: new Date().getTime(),
      name: "Test",
      percent: 12,
      rate: "not_limited",
      dateCreated: new Date().getTime(),
      isUse: false,
    },
    promocodeCopy: {},
    modalPicker: false,

    dialogCourse: false,
    dialogSocial: false,
    snackbar: {
      isOpen: false,
      text: "",
    },
    directoriesSnackbar: {
      toOpen: false,
      isOpen: false,
      text: "",
    },
    rules: [(value) => !!value || value == 0 || "Обязательно к заполнению"],
    courseId: null,
    authorId: null,
    authors: [],

    courseHeaders: [
      {
        text: "Название",
        align: "left",
        sortable: false,
        value: "course",
      },
      {
        text: "Автор",
        align: "left",
        sortable: false,
        value: "author",
      },
      {
        text: "Дата",
        align: "left",
        sortable: false,
        value: "dateCreated",
      },
      { text: "   ", align: "right", value: "actions", sortable: false },
    ],
    promocodeCourses: [],
    courses: [],
    courseOptionTable: {
      page: 1,
      itemsPerPage: 5,
      serverItemsLength: -1,
    },
  }),
  created() {
    this.getPromocode();
    this.getCourses();
    this.getPromocodeCourses();
    this.getAuthors();
  },
  methods: {
    getAuthors() {
      axios
        .post(
          this.$store.getters.apiAdminPanelV1 + "/author/list",
          this.discountCopy,
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.authors = res.data;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    cancelPromocodeBtn() {
      this.dialogPromocode = false;
    },
    savePromocodeBtn() {
      if (this.$refs.promocodeForm.validate()) {
        if (this.promocodeCopy.toDateForm) {
          this.promocodeCopy.toDate = new Date(
            this.promocodeCopy.toDateForm
          ).getTime();
        }
        axios
          .post(
            this.$store.getters.apiAdminPanelV1 + "/promocode",
            this.promocodeCopy,
            {
              headers: {
                authorization: this.$store.getters.adminPanelHeaderAuth,
              },
            }
          )
          .then(() => {
            this.promocodeCopy = {};
            this.dialogPromocode = false;
            this.getPromocode();
          })
          .catch((err) => {
            this.snackbar.isOpen = true;
            this.snackbar.text = err.response.data || err.stack;
          });
      }
    },
    openFormPromocode() {
      this.promocodeCopy = Object.assign({}, this.promocode);
      if (this.promocodeCopy.toDate) {
        this.promocodeCopy.toDateForm = new Date(this.promocodeCopy.toDate)
          .toISOString()
          .substr(0, 10);
      }
    },
    blockPromocodeBtn(isUse) {
      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV1 + "/promocode/use",
          {
            id: this.$route.params.id,
            isUse: isUse,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.getPromocode();
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    getPromocode() {
      this.snackbar.isOpen = false;
      axios
        .get(this.$store.getters.apiAdminPanelV1 + "/promocode", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.promocode = res.data;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },

    cancelCourseBtn() {
      this.dialogCourse = false;
      this.directoriesSnackbar.isOpen = false;
      this.courseId = null;
      this.authorId = null;
    },
    saveCourseBtn() {
      if (this.$refs.course.validate()) {
        axios
          .post(
            this.$store.getters.apiAdminPanelV1 + "/promocode/course",
            {
              promocodeId: this.$route.params.id,
              courseId: this.courseId,
            },
            {
              headers: {
                authorization: this.$store.getters.adminPanelHeaderAuth,
              },
            }
          )
          .then(() => {
            this.dialogCourse = false;
            this.courseId = null;
            this.authorId = null;
            this.getPromocodeCourses();
          })
          .catch((err) => {
            this.snackbar.isOpen = true;
            this.snackbar.text = err.response.data || err.stack;
          });
      }
    },
    getCourses() {
      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV1 + "/course/list",
          {
            authorId: this.authorId,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.courses = res.data;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },

    getPromocodeCourses() {
      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV1 + "/promocode/courses/get",
          {
            optionTable: this.courseOptionTable,
            promocodeId: this.$route.params.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.courseOptionTable = res.data.optionTable;
          this.promocodeCourses = res.data.items;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },

    courseTableChange(data) {
      this.courseOptionTable.page = data.page;
      this.courseOptionTable.itemsPerPage = data.itemsPerPage;
      this.getPromocodeCourses();
    },

    onClickRow(item) {
      if (!confirm("Удалить?")) {
        return;
      }

      axios
        .delete(this.$store.getters.apiAdminPanelV1 + "/promocode/course", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: item.id,
          },
        })
        .then(() => {
          this.getPromocodeCourses();
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
  },
};
</script>
