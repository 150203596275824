<template>
  <div class="page-container">
    <div class="container-title-block">
      <div class="title-block-text">Документы</div>
    </div>
    <div class="mb-10">
      <v-autocomplete
        style="z-index: 100"
        outlined
        :items="documents"
        v-model="document"
        label="Список документов"
        @change="selectDocument()"
        hide-details
      >
        <template v-slot:item="{ item }">
          <v-checkbox
            v-model="item.isActive"
            @change="changeActiveDocument(item)"
          ></v-checkbox>
          {{ item.text }}
        </template>
      </v-autocomplete>
    </div>

    <div class="">
      <div class="trumbowyg-block">
        <trumbowyg :config="config" v-model="text"></trumbowyg>
      </div>
      <div class="trumbowyg-block">
        <div class="doc-button">
          <v-btn depressed class="button-blue" @click="updateDocumentBtn()">
            Сохранить
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "documents",
  data: () => ({
    content: null,
    selectedDoc: {},
    document: "privacyPolicy",
    text: "",
    documents: [],
    config: {
      lang: "ru",
      btns: [
        ["viewHTML"],
        ["undo", "redo"],
        ["formatting"],
        ["strong", "em", "del"],
        ["superscript", "subscript"],
        ["link"],
        ["insertImage"],
        ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
        ["unorderedList", "orderedList"],
        ["horizontalRule"],
        ["removeformat"],
      ],
    },
  }),

  computed: {},

  watch: {},

  created() {
    this.getDocuments();
  },

  methods: {
    getDocuments() {
      axios
        .get(this.$store.getters.apiAdminPanelV4 + "/documents", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.document = res.data[0].key;
            this.selectedDoc = res.data[0];
            this.text = res.data[0].description;
            for (let item of res.data) {
              let document = {
                text: item.title,
                value: item.key,
                description: item.description,
                isActive: item.isActive,
                id: item.id,
              };
              this.documents.push(document);
            }
          }
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    selectDocument() {
      for (let item of this.documents) {
        if (item.value == this.document) {
          this.text = item.description;
          this.selectedDoc = item;
          break;
        }
      }
    },
    changeActiveDocument(data) {
      let formData = new FormData();
      formData.append("id", data.id);
      formData.append("key", data.value);
      formData.append("description", data.description);
      formData.append("isActive", data.isActive);
      formData.append("type", "html");

      axios
        .post(this.$store.getters.apiAdminPanelV4 + "/documents", formData, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    updateDocumentBtn() {
      let formData = new FormData();
      formData.append("id", this.selectedDoc.id);
      formData.append("key", this.document);
      formData.append("description", this.text);
      formData.append("type", "html");

      axios
        .post(this.$store.getters.apiAdminPanelV4 + "/documents", formData, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then(() => {
          for (let item of this.documents) {
            if (item.value == this.document) {
              item.description = this.text;
              break;
            }
          }
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  padding: 40px 64px 0px 64px;
  height: 100%;
  width: 100%;
  position: relative;
  .container-title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    z-index: 100;

    .title-block-text {
      color: var(--app-black);
      font-size: 28px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 0px;
    }
    .title-block-search {
      width: 300px;
    }
  }
}

.trumbowyg-block {
  margin: 0 auto;

}
.doc-button {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.trumbowyg-viewHTML-button {
  color: black;
}
</style>
