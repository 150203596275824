<template>
    <div class="version__info">
            <v-card-title  class="d-flex justify-space-between">
                <div class="d-flex">
                    <v-btn
                        depressed
                        class="mr-4"
                        @click="rout('versions')"
                    ><v-icon>mdi-chevron-left</v-icon></v-btn>
                    Информация
                </div>
                <v-btn
                    class="mx-0 button-action"
                    depressed
                    @click="rout('versionUpdate',versions.versionItems.versionId)"
                >Редактировать</v-btn>
            </v-card-title> 
        <!-- <div class="mx-4 d-flex version-title">
            <div   class="d-flex justify-space-between wid">
                <div>
                    <h3>Номер версии</h3><span>{{versions.versionNumber}}</span>
                </div>
                <div>
                    <h3>Платформа</h3><span>{{versions.deviceType}}</span>
                </div>
                <div></div>
            </div>
            <div>
                <h3>Обязательное обновление</h3><span><v-icon color="#b4b4b4" >mdi-check</v-icon></span>
            </div>
        </div> -->
        <v-data-table
            :headers="headerT"
            :items= "version"
            item-key="versionId"
            disable-filtering
            disable-sort
            mobile-breakpoint="0"
            hide-default-footer
            hide-default-header   
                     
        >
            <template v-slot:item ="{}">
                <tr class="version__info-table-title">
                    <td>
                        <div>
                            <h3>Номер версии</h3><span style="color:#000;">{{versions.versionNumber}}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <h3>Платформа</h3><span style="color:#000;">{{versions.deviceType}}</span>
                        </div>
                    </td>
                    <td>
                    </td>
                    <td>
                        <div>
                            <h3>Обязательное обновление</h3>
                            <v-icon v-if="versions.isRequired" color="#b4b4b4" >mdi-check</v-icon><v-icon v-else color="#b4b4b4">mdi-minus </v-icon>
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-data-table
            :headers="headers"
            :items= "versions.versionItems"
            item-key="versionId"
            disable-filtering
            disable-sort
            mobile-breakpoint="0"
            hide-default-footer
            class="table"
            
        >
            <template v-slot:item ="{item}">
                <tr class="sl tabs"  @click="rout('versionUpdate',versions.versionItems.versionId)">
                    <td>1</td>
                    <td>
                        <img style="width: 50px" :src="createImageURL(item.file)">
                    </td>
                    <td>{{item.title}}</td>
                    <td ><div style = "text-align: start !important">{{item.description}}</div></td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>

    import axios from 'axios';  

    export default {
        data () {
            return {
                versions: [],
                search: '',
                count:'',
                version : [{
                   versionNumber : "",
                   deviceType : "",
                   check : ""
                }]
            }
        },
        methods : {
            getVersion () {
                const id = this.$route.params.id;

                axios.get(this.$store.getters.apiAdminPanelV3 + '/version',{
                    headers: {
                        'authorization': this.$store.getters.adminPanelHeaderAuth
                    },
                    params: {
                        versionId: id
                    }
                })
                .then(res => {
                    this.versions = res.data
                    window.console.log(this.versions)
                })
                .catch(err => {
                    this.snackbar.isOpen = true
                    this.snackbar.text = err.response.data || err.stack
                })
            },
            rout(name , params) {
                if(typeof(params) != "undefined") {
                    this.$router.push({name:name , params: {id: params}});
                } else {
                    this.$router.push({name:name});
                }
            },
            updateWidth() {
                let size = document.querySelector('.tabs td:nth-child(1)').offsetWidth 
                + document.querySelector('.tabs td:nth-child(2)').offsetWidth 
                + document.querySelector('.tabs td:nth-child(3)').offsetWidth;

                let item  = document.querySelector('.wid');
                item.style.color = "red;";

                item.style.width =  size + "px";

                window.console.log(size + "px");
                window.console.log(item.style.width)
                
            },
        },
        created() {
            this.getVersion();
        },
        mounted() {
            // window.addEventListener('resize', this.updateWidth);
        },
        computed: {
            headers () {
                return [
                    { text: '№', value: 'dateСreation',sortable: false,width:"10%",},
                    { text: 'Иконка', value: 'platform',sortable: false,width:"10%" },
                    { text: 'Заголовок', value: 'versionNumber',sortable: false,width:"30%" },
                    { text: 'Описание', value: 'mandatoryUpdates',sortable: false, align: "start",width:"40%" },
                ]
            },
            headerT () {
                return [
                    { text: 'Номер', value: 'dateСreation',sortable: false,width:"12%" },
                    { text: 'Заголовок', value: 'versionNumber',sortable: false,width:"10%" },
                    { text: 'Заголовок', value: 'versionNumber',sortable: false,width:"30%" },
                    { text: 'Описание', value: 'mandatoryUpdates',sortable: false, align: "start",width:"40%" },
                ]
            },
        },
  }
</script>

<style lang="scss" scoped>
    .version__info {
        margin-left: 0;
        &-title {
            h2 {
                font-weight: 500;
                font-size: 20px;
                display: block;
            }
            button {
                margin-right: 22px;
            }
            margin-top: 8px;
            margin-bottom: 0px;
        }
        table tr td:nth-child(2){
            min-width: 0 !important;
            i {
                color: #6d81df;
            }
        }

        .add-btn {
            font-size: 15px;
            opacity: 1 ;
            font-weight: 400 !important;
            border-radius: 8px;
        }
        .wid{
           // width: 200px;
            width: 57%;
            margin-bottom: 20px;
            span {
                color: #000 !important;
            }
        }
        &-table-title {
            //color: #959595;
            max-width: 1600px !important;
            h3{
                font-size: 24px !important;
                font-weight: 400 !important;
            }
            span {
               font-size: 16px!important;
               font-weight: 400 !important;
            }
            td{
                background-color: rgb(255, 255, 255) !important;
                cursor:initial;
            }
        }
    }
</style>
