<template>
  <v-app>
    <ErrorAlert />
    <SuccessAlert />
    <router-view />
  </v-app>
</template>
<script>
import ErrorAlert from "@/components/main/alerts/ErrorAlert";
import SuccessAlert from "@/components/main/alerts/SuccessAlert";
export default {
  components: {
    ErrorAlert,
    SuccessAlert,
  },
  mounted() {
    if (window.PANEL_HTML_PAGE_TITLE) {
      document.title = window.PANEL_HTML_PAGE_TITLE;
    }
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

@import "styles/vuetifyEditStyles.scss";
@import "styles/main.scss";
@import "styles/button.scss";
@import "styles/colors.scss";

* {
  font-family: "Inter", sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
html {
  overflow: auto !important;
}
body {
  background-color: var(--app-grey-1);
  a {
    text-decoration: none;
  }
}
</style>

