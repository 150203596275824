<template>
  <v-file-input
    truncate-length="50"
    :value="typeof value == 'object' ? value : newValue"
    @change="setFile"
    :label="label"
    outlined
    :rules="checkRules"
    color="#0078FF"
    :accept="acceptFiles"
    :hide-details="hideDetails"
  ></v-file-input>
</template>
<script>
export default {
  props: {
    label: { type: String, default: "" },
    valid: { type: Boolean, default: false },
    acceptFiles: { type: String, default: "*" },
    hideDetails: { type: Boolean, default: false },
    value: {},
  },
  data: () => ({
    newValue: null,
  }),
  methods: {
    setFile($event){
      this.$emit('input', $event)
      this.$emit('change', $event)
    }
  },
  created() {
    if (this.value) {
      if (typeof this.value == "string") {
        this.src = this.value;
        const obj = new Object();
        obj.src = this.src;
        obj.name = "Ранее загруженный файл";
        this.newValue = obj;
      } else {
        this.src = URL.createObjectURL(this.value);
      }
    }
  },
  computed: {
    checkRules() {
      if (this.valid) {
        return [(value) => !!value || "Обязательно к заполнению"];
      }
      return [];
    },
  },
  mounted() {},

  watch: {
    value() {
      if (this.value) {
        if (typeof this.value == "string") {
          this.src = this.value;
          const obj = new Object();
          obj.src = this.src;
          obj.name = "Ранее загруженный файл";
          this.newValue = obj;
        } else {
          this.src = URL.createObjectURL(this.value);
        }
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
