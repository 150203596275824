<template>
  <div v-if="loading" class="page-container">
    <div class="container-title-block">
      <div class="title-block-text">Баннеры</div>
      <v-btn depressed class="button-blue" @click="openDialog">
        Добавить баннер
      </v-btn>
    </div>
    <div class="container-subtitle-block"></div>
    <div class="container-table">
      <Table
        :items="itemsAll"
        :headers="headers"
        draggable
        @click:draggable="newSort"
        headerFixed
        @click:row="openDialog"
      >
        <template v-slot:[`item.index`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.image`]="{ item }">
          <img
          class="table-icon"
            :src="item.image"
           
          />
        </template>
       
      </Table>
    </div>
    <Dialog
      v-model="dialog"
      refForm="testForm"
      :titleText="dialogItems.headerText"
      :mainItems="[
        {
          type: 'text',
          label: 'Текст',
          value: this.selectedItem.body,
          vmodel: (e) => (this.selectedItem.body = e),
          valid: true,
        },
        {
          type: 'image',
          label: 'Изображение',
          value: this.selectedItem.image,
          vmodel: (e) => (this.selectedItem.image = e),
          valid: true,
        },
        {
          type: 'text',
          label: 'Ссылка',
            valid: true,
          value: this.selectedItem.link,
          vmodel: (e) => (this.selectedItem.link = e),
        },
      ]"
      @click:success="sendItem"
      @click:delete="deleteItem"
      :buttonsFooter="dialogItems.buttonsFooter"
    >
    </Dialog>
  </div>
</template>
  <script>
export default {

  data: () => ({
    loading: true,
    search: "",
    items: [],
    itemsAll: [],
    dialog: false,
    selectedItem: {},
    dialogItems: {
      headerText: "",
      buttonsFooter: {},
    },
    headers: [
      {
        text: "",
        align: "flex-left",
        sortable: false,
        value: "index",
      },
      {
        text: "Картинка",
        align: "flex-left",
        sortable: false,
        value: "image",
      },
      {
        text: "Текст",
        align: "flex-left",
        sortable: false,
        value: "body",
      },
      {
        text: "Ссылка",
        align: "flex-left",
        sortable: false,
        value: "link",
      },
    ],
  }),
  methods: {
    newSort(value, item) {
      console.log(value, item);
    },
    openDialog(item) {
      if (item && item.id) {
        this.dialogItems = {
          headerText: "Редактировать баннер",
          buttonsFooter: {
            success: {
              title: "Сохранить",
              loading: true,
            },
            delete: {
              title: "Удалить",
              loading: true,
            },
          },
        };
        this.selectedItem = Object.assign({}, item);
        this.dialog = true;
      } else {
        this.dialogItems = {
          headerText: "Добавить баннер",
          buttonsFooter: {
            success: {
              title: "Добавить",
              loading: true,
            },
          },
        };
        this.selectedItem = {};
        this.dialog = true;
      }
    },
    getItems() {
      this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/banner/list", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.itemsAll = res.data;
          this.loading = true;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },

    sendItem() {
      let formData = new FormData();
      if (this.selectedItem.id) {
        formData.append("id", this.selectedItem.id);
      }
      formData.append("body", this.selectedItem.body);
      formData.append("image", this.selectedItem.image || "");
      formData.append("link", this.selectedItem.link || "");
      this.$axios
        .post(this.$store.getters.apiAdminPanelV4 + "/banner", formData, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then(() => {
          this.getItems();
          this.dialog = false;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    goToDetail(item) {
      this.$router.push({ name: "Test", params: { id: item.id } });
    },
    deleteItem() {
      this.$axios
        .delete(this.$store.getters.apiAdminPanelV4 + "/banner", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.selectedItem.id,
          },
        })
        .then(() => {
          this.getItems();
          this.dialog = false;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
  },
  created() {
    this.getItems();
  },
};
</script>
  <style lang="scss" scoped>
.page-container {
  padding: 40px 64px 0px 64px;
  height: 100%;
  width: 100%;
  position: relative;
  .container-title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-block-text {
      color: var(--app-black);
      font-size: 28px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 0px;
    }
    .title-block-search {
      width: 300px;
      min-width: 300px;
    }
  }
  .container-subtitle-block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 0px 12px 0px;
    position: relative;
    .buttons-block-search {
      min-width: 300px;
    }
    .subtitle-block-count {
      font-size: 16px;
      color: var(--app-grey-3);
    }
  }
  .container-table {
    height: calc(100vh - 180px);
    .table-icon {
         width: 48px;
              height: 48px;
              border-radius: 8px;
              object-fit: cover;
    }
  }
}
</style>