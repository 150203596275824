<template>
<div class="page-container">
    <div class="container-title-block">
      <div class="title-block-text">Удаленные пользователи</div>
      <div class="title-block-search">
        <SearchInput class="ml-4" v-model="search" label="Поиск пользователя" />
      </div>
    </div>
    <div class="container-table">
      <Table
        :items="itemsAll"
        :headers="usersHeaders"
        headerFixed
        @click:row="onClickRowEmployee"
        :noClickSlots="['checkbox', 'button']"
      >
        <template v-slot:[`header.checkbox`]>
          <CheckBox
            v-model="allChecked"
            :main="true"
            @change="clickHeaderCheckBox"
            :background="true"
            color="#0078FF"
          />
        </template>
        <template v-slot:[`item.checkbox`]="{ item }">
          <CheckBox
            v-model="item.checkbox"
            :key="rerenderKey"
            @change="checkboxMeth($event, item)"
            color="#0078FF"
          />
        </template>
        <template v-slot:[`item.fullName`]="{ item }">
          <template v-if="item.fullName">
            {{ item.fullName }}
          </template>
          <template v-else-if="item.email">
            {{ item.email }}
          </template>
          <template v-else-if="item.phoneNumber">
            {{ item.phoneNumber | VMask("+# (###) ### ##-##") }}
          </template>
        </template>
        <template v-slot:[`item.button`]="{ item }">
          <v-btn 
            depressed
            class="button-blue"
            :class="hideButtons ? 'hide-on-select' : ''"
            @click="sendItems(item.id)">
              Восстановить
          </v-btn>
        </template>
        
      </Table>
    </div>
    <div class="container-footer">
      <v-btn 
          depressed
          class="button-blue"
          :class="hideButtons ? '' : 'hide-on-select'"
          @click="sendItems">
            Восстановить выбранных
        </v-btn>
    </div>
</div>
</template>

<script>
import SearchInput from "@/components/main/inputs/SearchInput";
// import FilterBlock from "@/components/main/FilterBlock";
import CheckBox from "@/components/main/inputs/CheckBox.vue";
// import PhoneInput from "@/components/main/inputs/PhoneInput.vue";
// import TextInput from "@/components/main/inputs/TextInput.vue";
// import DateInput from "@/components/main/inputs/DateInput.vue";

export default {
    components: {
      SearchInput,
      CheckBox,
    },

    data: () => ({
      search: "",
      itemsAll: [],
      selectedItems: [],
      allChecked: false,
      hideButtons: false,
      rerenderKey: 0,
      usersHeaders: [
        {
          align: "flex-left",
          sortable: false,
          value: "checkbox",
        },
        {
          text: "ФИО",
          align: "flex-left",
          sortable: false,
          value: "fullName",
        },
        {
          text: "",
          align: "flex-left",
          sortable: false,
          value: "button",
        },
      ],
    }),
    methods: {
      onClickRowEmployee(row) {
        this.$router.push({ name: "User", params: { id: row.id } });
      },
      clickHeaderCheckBox(bool) {
        this.selectedItems = [];
        this.itemsAll.forEach((element) => {
          element.checkbox = bool;
          if (bool) {
            this.selectedItems.push({ ...element, checkbox: true });
          }
        });
      },
      checkboxMeth(bool, item) {
        if (bool) {
          this.selectedItems.push({ ...item, checkbox: true });
        } else {
          this.selectedItems = this.selectedItems.filter((v) => v.id != item.id);
        }
        this.selectedItems.length == this.itemsAll.length
          ? (this.allChecked = true)
          : (this.allChecked = false);
        this.rerenderKey++
      },
      async getItems() {
        await this.$axios
          .get(this.$store.getters.apiAdminPanelV4 + "/user/deleted/list", {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          })
          .then((res) => {
            this.itemsAll = res.data;
            this.allChecked = false;

            if (this.itemsAll.length > 0) {
              this.itemsAll.forEach((element) => {
                element.checkbox = false;
                element.fullName =
                  (element.lastName ? element.lastName + " " : "") +
                  (element.firstName ? element.firstName + " " : "") +
                  (element.middleName ? element.middleName : "");
              });
            }
          })
          .catch((e) => {
            console.log(e)
          });
      },
      async sendItems(item) {
        const itemsToSend = this.selectedItems.map((i) => i.id)
        await this.$axios
        .post(this.$store.getters.apiAdminPanelV4 + "/user/deleted/recover", 
          {
            userIds : itemsToSend.length > 0 ? itemsToSend : [item]
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          })
          .catch((e) => {
            console.log(e)
          });
        await this.getItems()
      }
    },
    watch: {
      selectedItems(newVal) {
        this.hideButtons = newVal.length != 0
      }
    },
    mounted() {
      this.getItems()
    }
}
</script>

<style scoped lang="scss">
.page-container {
  padding: 40px 64px 0px 64px;
  height: 100%;
  width: 100%;
  position: relative;

  .container-title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-block-text {
      color: var(--app-black);
      font-size: 28px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 0px;
    }
    .title-block-search {
      width: 300px;
    }
  }

  .container-subtitle-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 40px 0px 0px 0px;
    position: relative;
  }

  .container-table{
    margin-top: 40px;
  }

  .container-footer{
    margin-top: 24px;
  }

  .hide-on-select{
    opacity: 0;
    user-select: none;
    pointer-events: none;
  }
}
</style>