import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueCookies from "vue-cookies";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    protocol: window.PANEL_HTTP_PROTOCOL,
    host: window.PANEL_API_HOST,
    port: window.PANEL_PORT,
    protocolWS: window.PANEL_SOCKETS_PROTOCOL,
    apiAdminPanelV1: "/api/v1/adminPanel",
    apiAdminPanelV2: "/api/v2/adminPanel",
    apiAdminPanelV3: "/api/v3/adminPanel",
    apiAdminPanelV4: "/api/v4/adminPanel",
    apiV7: "/api/v7",
    adminPanelKeyCookie: window.PANEL_API_KEY,
    logoLogin: window.PANEL_LOGO,
    user: {},
    mailTo: "",
    error: false,
    errorText: "",
    success: false,
    successText: "",
  },
  getters: {
    apiAdminPanelV1: (state) => {
      return (
        state.protocol +
        "://" +
        state.host +
        ":" +
        state.port +
        state.apiAdminPanelV1
      );
    },
    apiAdminPanelV2: (state) => {
      return (
        state.protocol +
        "://" +
        state.host +
        ":" +
        state.port +
        state.apiAdminPanelV2
      );
    },
    apiAdminPanelV3: (state) => {
      return (
        state.protocol +
        "://" +
        state.host +
        ":" +
        state.port +
        state.apiAdminPanelV3
      );
    },
    apiAdminPanelV4: (state) => {
      return (
        state.protocol +
        "://" +
        state.host +
        ":" +
        state.port +
        state.apiAdminPanelV4
      );
    },
    apiV7: (state) => {
      return (
        state.protocol + "://" + state.host + ":" + state.port + state.apiV7
      );
    },
    baseURL: (state) => {
      return state.protocol + "://" + state.host + ":" + state.port;
    },
    wsURL: (state) => {
      return (
        state.protocolWS + "://" + state.host + ":" + state.port + "/webSocket"
      );
    },
    adminPanelHeaderAuth: (state) => {
      return "Bearer " + VueCookies.get(state.adminPanelKeyCookie);
    },
    user: (state) => {
      return state.user;
    },
    mailTo: (state) => {
      return state.mailTo;
    },
  },
  mutations: {
    userSet(state, user) {
      state.user = user;
    },
    errorSet(state, boolean) {
      state.error = boolean;
      setTimeout(() => {
        state.error = false;
      }, 4000);
    },
    errorTextSet(state, err) {
      if (err && typeof err != "string") {
        if (err.response) {
          state.errorText = err.response.data;
        } else {
          state.errorText = "Проблема с подключением";
        }
      } else if (err && typeof err === "string") {
        state.errorText = err;
      } else {
        state.errorText = "Ошибка отправки данных";
      }
    },
    successSet(state, boolean) {
      state.success = boolean;
      setTimeout(() => {
        state.success = false;
      }, 4000);
    },
    successTextSet(state, value) {
      state.successText = value;
    },
  },
  actions: {
    authorizationAdminPanel: function (context) {
      return axios
        .get(context.getters.apiAdminPanelV4 + "/auth/check", {
          headers: {
            authorization: context.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          if (res.data.result === "ok") {
            context.commit("userSet", res.data.user);
            setTimeout(() => {
              if (
                window.location.pathname !== "/login" &&
                window.location.pathname !== "/"
              ) {
                localStorage.setItem("lastPage", window.location.pathname);
              }
            }, 100);
            return true;
          } else {
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    },
  },
  modules: {},
});
