<template>
<div class="checkbox-block">
    <v-checkbox
      v-model="bool"
      :label="label"
      hide-details
      color="#0078FF"
  ></v-checkbox>
  
</div>

</template>
<script>
export default {
  props: {
    label: { type: String, default: "" },
    valid: { type: Boolean, default: false },
    value: {type: Boolean, default: false},
  },
  data: () => ({
    
  }),
  methods: {

  },
  created() {
  },
   computed: {
    bool: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

  },
  mounted() {},

  watch: {},
};
</script>
<style scoped lang="scss">
.checkbox-block{
    display: flex;
    align-items: center;
    border: 1px solid #DDDDEA;
    border-radius: 6px;
    padding: 0px 12px ;
    margin-bottom: 20px;
    height: 56px;
}
.v-input--selection-controls {
    margin-top: 0px; 
    padding-top: 0px; 
}

</style>
