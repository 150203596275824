import Vue from "vue";
import VueRouter from "vue-router";

import Documents from "../views/Documents.vue";
import SettingMobile from "../views/SettingMobile.vue";
import Push from "../views/Push.vue";
import PushDetail from "../views/PushDetail.vue";
import MailTemplates from "../views/MailTemplates.vue";
import Versions from "../views/Version/Versions.vue";
import AddVersions from "../views/Version/VersionAdd";
import VersionDetailed from "../views/Version/VersionDetailed";
import VersionUpdate from "../views/Version/VersionUpdate";
import Banners from "../views/Banners/Banners.vue";
import ModerationUsers from "../views/ModerationUsers.vue";

import Discount from "../views/Discount";
import Promocode from "../views/Promocode";

Vue.use(VueRouter);

import store from "../store/index";

const routes = [
  {
    path: "/",
    name: "Layout",
    component: () => import("../views/Layout.vue"),
    async beforeEnter(to, from, next) {
      if (await store.dispatch("authorizationAdminPanel")) {
        next();
      } else {
        next({ name: "Login" });
      }
    },
    children: [
      {
        path: "users",
        name: "Users",
        component: () => import("../views/Users/Users.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "user/:id",
        name: "User",
        component: () => import("../views/Users/User.vue"),
        meta: { parent: ["Users"] },
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "tags",
        name: "Tags",
        component: () => import("../views/Tags/Tags.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "groups",
        name: "Groups",
        component: () => import("../views/Groups/Groups.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "group/:id",
        name: "Group",
        meta: { parent: ["Groups"] },
        component: () => import("../views/Groups/Group.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "tests",
        name: "Tests",
        component: () => import("../views/Tests/Tests.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "test/:id",
        name: "Test",
        meta: { parent: ["Tests"] },
        component: () => import("../views/Tests/Test.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "courses",
        name: "Courses",
        component: () => import("../views/Courses/Courses.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "course/:id",
        name: "Course",
        meta: { parent: ["Courses"] },
        component: () => import("../views/Courses/Course.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "topics",
        name: "Topics",
        component: () => import("../views/Topics/Topics.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "newsArticles",
        name: "NewsArticles",
        component: () => import("../views/News/NewsArticles.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "news/:id",
        name: "News",
        meta: { parent: ["NewsArticles"] },
        component: () => import("../views/News/News.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "lesson/:id",
        name: "Lesson",
        meta: { parent: ["Courses"] },
        component: () => import("../views/Lessons/Lesson.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },

      {
        path: "documents",
        name: "documents",
        component: Documents,
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "mailTemplates",
        name: "mailTemplates",
        component: MailTemplates,
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "settingMobile",
        name: "settingMobile",
        component: SettingMobile,
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },

      {
        path: "discount/:id",
        name: "discount",
        component: Discount,
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "promocode/:id",
        name: "promocode",
        component: Promocode,
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "push",
        name: "push",
        component: Push,
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "push/:id",
        name: "pushDetail",
        component: PushDetail,
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "versions",
        name: "versions",
        component: Versions,
        beforeEnter: async (to, from, next) => {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "version/add",
        name: "historlayoutadd",
        component: AddVersions,
        beforeEnter: async (to, from, next) => {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "version/details/:id",
        name: "versionDetails",
        component: VersionDetailed,
        beforeEnter: async (to, from, next) => {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "version/update/:id",
        name: "versionUpdate",
        component: VersionUpdate,
        beforeEnter: async (to, from, next) => {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "banners",
        name: "banners",
        component: Banners,
        beforeEnter: async (to, from, next) => {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
      {
        path: "moderation",
        name: "moderation",
        component: ModerationUsers,
        beforeEnter: async (to, from, next) => {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "Login" });
          }
        },
      },
    ],
  },
  // {
  //   path: "/mailConfirmation/:id",
  //   name: "mailConfirmation",
  //   component: MailConfirmation,
  // },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    beforeEnter: async (to, from, next) => {
      if (await store.dispatch("authorizationAdminPanel")) {
        if (localStorage.getItem("lastPage") !== null) {
          const lastPage = window.localStorage
            .getItem("lastPage")
            .replace("/", "")
            .replace(/"/g, "");
          next({
            path: lastPage,
          });
        } else {
          next({ name: "Layout" })
        }
      } else {
        next();
      }
    },
  },
  {
    path: "*",
    beforeEnter: async (to, from, next) => {
      if (await store.dispatch("authorizationAdminPanel")) {
        next();
      } else {
        next({ name: "Login" });
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
