<template>
  <div class="versions">
    <v-card-title class="d-flex justify-space-between">
      История версий
      <div>
        <v-btn class="button-action" color="#3651d2" @click="rout" depressed>
          Добавить версию
        </v-btn>
      </div>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="history"
      item-key="id"
      disable-filtering
      disable-sort
      mobile-breakpoint="1"
      :items-per-page="5"
    >
      <!-- <v-spacer></v-spacer> -->
      <template v-slot:item="{ item }">
        <tr
          class="sl"
          @contextmenu="ShowContextMenu($event, item)"
          @click="ShowDetails(item.versionId)"
        >
          <td>{{ new Date(item.dateCreated).toLocaleDateString() }}</td>
          <td>{{ item.deviceType }}</td>
          <td>{{ item.versionNumber }}</td>
          <td>
            <v-icon v-if="item.isRequired" color="#b4b4b4">mdi-check</v-icon
            ><v-icon v-else color="#b4b4b4">mdi-minus </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-menu
      v-model="contextMenu.show"
      :position-x="contextMenu.x"
      :position-y="contextMenu.y"
      absolute
      offset-y
    >
      <div @click="editPagesShow" class="context-menu">Редактировать</div>
      <div @click="checkDelete" class="context-menu">Удалить</div>
    </v-menu>
    <div>
      <v-dialog v-model="dialog.checkDelete" persistent max-width="400">
        <!-- <template v-slot:activator></template> -->
        <DialogCheckDelete @no="unShowDealog" @yes="deleteVersion" />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DialogCheckDelete from "@/components/DialogCheckDelete.vue";

export default {
  data() {
    return {
      history: [],
      idHistory: 0,
      optionTable: {
        page: 1,
        itemsPerPage: 5,
        serverItemsLength: -1,
      },
      contextMenu: {
        show: false,
        x: 0,
        y: 0,
      },
      dialog: {
        checkDelete: false,
      },
      search: "",
    };
  },
  components: {
    DialogCheckDelete,
  },
  methods: {
    unShowDealog() {
      this.dialog.checkDelete = false;
    },
    ShowDetails(id) {
      this.$router.push({ name: "versionUpdate", params: { type: 1, id: id } });
    },
    editPagesShow() {
      this.$router.push({
        name: "versionUpdate",
        params: { type: 1, id: this.idHistory },
      });
    },
    checkDelete() {
      this.dialog.checkDelete = true;
    },
    ShowContextMenu(e, item) {
      e.preventDefault();

      this.contextMenu.show = false;
      this.contextMenu.x = e.clientX;
      this.contextMenu.y = e.clientY;

      this.idHistory = item.versionId;

      this.$nextTick(() => {
        this.contextMenu.show = true;
      });
    },
    rout() {
      this.$router.push({ name: "historlayoutadd" });
    },
    getVersions() {
      axios
        .post(
          this.$store.getters.apiAdminPanelV3 + "/version/list",
          {},
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.history = res.data.items;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    deleteVersion() {
      axios
        .delete(this.$store.getters.apiAdminPanelV3 + "/version", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            versionId: this.idHistory,
          },
        })
        .catch((err) => {
          window.console.log(err);
        });
      this.dialog.checkDelete = false;
      this.getVersions();
    },
  },
  created() {
    this.getVersions();
  },
  computed: {
    headers() {
      return [
        { text: "Дата создания", value: "dateСreation", width: "20%" },
        { text: "Платформа", value: "platform", sortable: false, width: "10%" },
        {
          text: "Номер версии",
          value: "versionNumber",
          sortable: false,
          width: "20%",
        },
        {
          text: "Обязательное обновление",
          value: "mandatoryUpdates",
          sortable: false,
          align: "start",
          width: "40%",
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.versions {
  margin-left: 6px;
  &-title {
    line-height: 1.3;
    h2 {
      font-weight: 500;
      font-size: 20px;
    }
    padding-bottom: 2px;
  }
  .add-btn {
    background-color: #1d39ca !important;
    font-size: 15px;
    opacity: 1;
    i {
      font-size: 13px !important;
    }
    font-weight: 400 !important;
    border-radius: 8px;
    span {
      color: #ffffff !important;
      letter-spacing: normal;
    }
  }
}
.context-menu {
  background-color: rgb(241, 241, 241) !important;
  cursor: pointer;
  padding: 10px;
}
.versions table td:nth-child(2) {
  min-width: 0 !important;
}
</style>
