<template>
  <div>
    <div class="image-block" v-if="!bottomImg">
      <v-file-input
        truncate-length="50"
        :value="typeof value == 'object' ? value : newValue"
        @change="change($event)"
        :label="label"
        outlined
        :disabled="disabled"
        :rules="checkRules"
        color="#0078FF"
        :accept="acceptFiles"
        @click:clear="clear"
      ></v-file-input>
      <div @click="$emit('deleteVideo')" class="image-block-img">
        <v-icon :color="colorIcon" v-if="!src || !showImg">
          {{ mdiIcon }}
        </v-icon>
        <img
          class="img-no-tooltip"
          v-if="src && showImg && !imgAndVideo"
          @click="dialogImg = true"
          :src="src"
        />
        <img
          class="imgtooltip"
          v-if="src && showImg && tooltipImage && acceptFiles == 'image/*'"
          :src="src"
        />
      </div>
      <DialogImage :src="src" v-model="dialogImg" />
    </div>
    <div class="d-flex flex-column" v-if="bottomImg">
      <div class="d-flex">
        <v-file-input
          truncate-length="50"
          :value="typeof value == 'object' ? value : newValue"
          @change="change($event)"
          :label="label"
          outlined
          :disabled="disabled"
          :rules="checkRules"
          color="#0078FF"
          :accept="acceptFiles"
          @click:clear="clear"
        ></v-file-input>
      </div>
      <img
        class="img-bottom"
        v-if="src && acceptFiles == 'image/*'"
        :src="src"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    imgAndVideo: { type: Boolean, default: false },
    acceptFiles: { type: String, default: "image/*" },
    label: { type: String, default: "" },
    valid: { type: Boolean, default: false },
    mdiIcon: { type: String, default: "mdi-paperclip" },
    colorIcon: { type: String, default: "#8D8D8D" },
    value: {},
    showImg: { type: Boolean, default: true },
    tooltipImage: { type: Boolean, default: false },
    dialogImage: { type: Boolean, default: true },
    bottomImg: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    src: "",
    newValue: null,
    dialogImg: false,
  }),
  methods: {
    clear() {
      this.$emit("clearImage");
      this.$emit("updateForm");
    },
    ckickItem(item) {
      this.$emit("ckickItem", item);
    },
    change(e) {
      if (e) {
        this.src = URL.createObjectURL(e);
        this.$emit("input", e);
        this.$emit("uploadFile");
        this.$emit("updateForm");
        this.$emit("canvasImage");
        this.$emit("savePersonoficationSplash", e);
        this.$emit("savePersonoficationSplashLoading", e);
      } else {
        this.src = "";
        this.$emit("input", null);
        this.$emit("updateForm");
      }
    },
  },
  created() {
    if (this.value) {
      if (typeof this.value == "string") {
        this.src = this.value;
        const image = new Object();
        image.src = this.src;
        image.name = "Ранее загруженный файл";
        this.newValue = image;
      } else if(this.value > 0){
        this.src = URL.createObjectURL(this.value);
      }
    }
  },
  computed: {
    checkRules() {
      if (this.valid) {
        return [(value) => !!value || "Обязательно к заполнению"];
      }
      return [];
    },
  },
  mounted() {},

  watch: {
    value() {
      if (this.value) {
        if (typeof this.value == "string") {
          this.src = this.value;
          const image = new Object();
          image.src = this.src;
          image.name = "Ранее загруженный файл";
          this.newValue = image;
        } else if (this.value > 0){
          this.src = URL.createObjectURL(this.value);
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.img-block-bottom {
  width: 68px;
  height: 56px;
  border-radius: 6px;
  background-color: #f7f7fa;
  margin-left: 10px;
}
.img-bottom {
  height: auto;
  width: 100%;
  border-radius: 6px;
  object-fit: cover;
}
.image-block {
  display: flex;

  .image-block-img {
    margin-left: 10px;
    background: white;
    width: 55px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    .img-no-tooltip {
      width: 55px;
      height: 45px;
      object-fit: cover;
      border-radius: 6px;
      cursor: pointer;
    }
    .imgtooltip {
      position: absolute;
      visibility: hidden;
      transition: visibility 0s, opacity 0.5s linear;
      opacity: 0;
      top: 40%;
      left: 675px;
      width: 491px;
      transform: translate(0%, -50%);
    }
    .img-no-tooltip:hover ~ .imgtooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
