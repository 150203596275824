<template>
  <div v-if="main" @click="clickItem" class="checkbox" :style="styleObject">
    <div v-if="value" :style="styleLine"></div>
  </div>

  <div v-else @click="clickItem" class="checkbox" :style="styleObject">
    <v-icon v-if="value" color="white" :size="size - 3">mdi-check</v-icon>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    color: { type: String, default: "#979797" },
    defaultColor: { type: String, default: "#606060" },
    size: { type: String, default: "18" },
    main: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({}),
  computed: {
    styleObject() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        background: this.value ? this.color : "white",
        borderColor: this.value ? this.color : this.defaultColor,
      };
    },

    styleLine() {
      return {
        width: `${this.size - 8}px`,
        height: "2px",
        borderRadius: "2px",
        background: "white",
      };
    },
  },
  methods: {
    clickItem() {
      this.$emit("change", !this.value);
      this.$emit("input", !this.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox {
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>