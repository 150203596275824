<template>
    <v-card class="dialogDel">
        <v-card-title class="flex justify-space-between">
            <span class="text-h6">Вы точно хотите удалить?</span>
            <v-btn
                icon
                dark
                style = "color:black"
                @click="no"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <hr/>
        <v-container class="d-flex justify-space-between px-5 py-5">
            <v-btn
                class="px-7 button-cancel"
                @click="no"
                depressed  
            >
                Нет
            </v-btn>
            <v-btn
                class="px-8 button-dialog-accept"
                @click="yes"
                depressed  
            >
                Да
            </v-btn>
        </v-container>
    </v-card>
</template>
<script>
    export default {
      data () {
        return {
          title:"",
          cat:""
        }
      },
      methods : {
        no() {
            this.$emit('no');
        },
        yes() {
            this.$emit('yes');
        },
      }
    }
</script>
<style lang="scss" scoped>
.dialogDel{
    .button-cancel{
        width: 100px;
    }
    .button-dialog-accept {
        width: 100px;
    }
}
</style>