<template>
  <transition name="fade" v-if="value">
    <div class="dialog-image">
      <div class="dialog-background" @click="closeDialog"></div>
      <div
        :style="`width:${imageWidth}px; height: ${imageHeight}px;`"
        class="dialog-container"
        @click="closeDialog"
      >
        <img
          :style="`width:${imageWidth}px; height: ${imageHeight}px;`"
          class="container-image"
          :src="previewImage"
        />
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    src: { type: String },
  },
  data: () => ({
    previewImage: null,
    imageWidth: 0,
    imageHeight: 0,
  }),
  created() {
    this.dialogWidth(this.src);
  },
  updated() {
    this.dialogWidth(this.src);
  },
  methods: {
    closeDialog() {
      this.$emit("input", false);
    },
    dialogWidth(src) {
      const image = new Image();
      image.src = src;
      const widthScreen = window.screen.width;
      const heightScreen = window.screen.height;

      this.imageHeight = heightScreen - 200;
      this.imageWidth = widthScreen - 200;

      this.previewImage = src;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-image {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  .dialog-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: RGBA(112, 112, 112, 0.2);
    backdrop-filter: blur(10px);
    // backdrop-filter: blur(10px);
  }
  .dialog-container {
    z-index: 10;

    .container-image {
      object-fit: contain;
    }
  }
}

.fade-enter-active {
  .dialog-background {
    opacity: 0;
    transition: all 0.3s ease;
  }
  .dialog-container {
    transition: all 0.3s ease;
    transform: translateY(50px);
    opacity: 0;
  }
}
.fade-enter-to {
  transition: all 0.3s ease;
  .dialog-background {
    opacity: 1;
  }

  .dialog-container {
    transition: all 0.3s ease;
    opacity: 1;
    transform: translateY(0px);
  }
}
.fade-leave-active {
  transition: all 0.3s ease;
  .dialog-background {
    opacity: 1;
  }

  .dialog-container {
    opacity: 1;
    transform: translateY(0px);
  }
}
.fade-leave-to {
  .dialog-background {
    opacity: 0;
    transition: all 0.3s ease;
  }

  .dialog-container {
    transition: all 0.3s ease;
    opacity: 0;
    transform: translateY(20px);
  }
}
</style>