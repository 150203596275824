<template>
  <div class="author">
    <v-snackbar :top="true" v-model="snackbar.isOpen">
      {{ snackbar.text }}
      <v-btn @click="snackbar.isOpen = false" color="red" text> Close </v-btn>
    </v-snackbar>
    <div class="header-buttons">
      <v-btn depressed :to="{ name: 'push' }" class="button-back"
        ><v-icon>mdi-chevron-left</v-icon></v-btn
      >
      <div>
        <v-dialog
          max-width="500px"
          persistent
          v-if="!push.isSend"
          v-model="dialogPush"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              @click="openFormPush"
              depressed
              class="button-default"
              v-on="on"
              >Редактировать
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="dialog-title">
              <span>Редактирование</span>
              <v-icon @click="cancelPushBtn">mdi-close</v-icon>
            </v-card-title>

            <v-card-text>
              <div class="dialog-object">
                <v-text-field
                  outlined
                  label="Заголовок"
                  v-model="pushCopy.title"
                ></v-text-field>
              </div>
              <div class="dialog-object">
                <v-textarea
                  outlined
                  label="Текст"
                  no-resize
                  rows="3"
                  v-model="pushCopy.description"
                ></v-textarea>
              </div>
              <div class="dialog-object">
                <v-file-input
                  accept="image/*"
                  outlined
                  :rules="[
                    (value) =>
                      (value && value.size < 2148000000) ||
                      'Размер изображения не больше 2 Гб',
                  ]"
                  hint="Максимальный размер изображения 2 Гб"
                  persistent-hint
                  label="Фото"
                  v-model="pushCopy.newPhoto"
                ></v-file-input>
              </div>

              <div class="dialog-object">
                <v-menu
                  outlined
                  :close-on-content-click="false"
                  max-width="290px"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                  v-model="pushStartPublicationMenu"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      @blur="
                        pushStartPublicationDate = parseDate(
                          pushStartPublicationDateFormatted
                        )
                      "
                      @click:clear="pushStartPublicationDate = null"
                      clearable
                      label="Начало публикации"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-model="pushStartPublicationDateFormatted"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="pushStartPublicationMenu = false"
                    no-title
                    v-model="pushStartPublicationDate"
                  ></v-date-picker>
                </v-menu>
              </div>

              <div class="dialog-object">
                <v-menu
                  :close-on-content-click="false"
                  max-width="290px"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                  v-model="pushEndPublicationMenu"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      @click:clear="
                        pushEndPublicationDate = parseDate(
                          pushEndPublicationDateFormatted
                        )
                      "
                      clearable
                      label="Конец публикации"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-model="pushEndPublicationDateFormatted"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="pushEndPublicationMenu = false"
                    no-title
                    v-model="pushEndPublicationDate"
                  ></v-date-picker>
                </v-menu>
              </div>

              <div style="padding-top: 20px">
                <span style="font-size: 16px"
                  >Период регистрации пользователя</span
                >
              </div>

              <div class="dialog-object">
                <v-menu
                  :close-on-content-click="false"
                  max-width="290px"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                  v-model="pushRegUserFromDateMenu"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      @blur="
                        pushRegUserFromDate = parseDate(
                          pushRegUserFromDateFormatted
                        )
                      "
                      @click:clear="pushRegUserFromDate = null"
                      clearable
                      label="Выбор даты «от»"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-model="pushRegUserFromDateFormatted"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="pushRegUserFromDateMenu = false"
                    no-title
                    v-model="pushRegUserFromDate"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="dialog-object">
                <v-menu
                  :close-on-content-click="false"
                  max-width="290px"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                  v-model="pushRegUserToMenu"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      @click:clear="
                        pushRegUserToDate = parseDate(pushRegUserToFormatted)
                      "
                      clearable
                      label="Выбор даты «по»"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-model="pushRegUserToFormatted"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @input="pushRegUserToMenu = false"
                    no-title
                    v-model="pushRegUserToDate"
                  ></v-date-picker>
                </v-menu>
              </div>

              <div class="dialog-object">
                <v-select
                  outlined
                  :items="typeDevices"
                  item-text="name"
                  item-value="id"
                  label="Тип устройства"
                  v-model="pushCopy.typeDevice"
                ></v-select>
              </div>
              <div class="dialog-object">
                <v-select
                  outlined
                  :items="availabilityPurchases"
                  item-text="name"
                  item-value="id"
                  label="Наличие покупок"
                  v-model="pushCopy.availabilityPurchase"
                ></v-select>
              </div>
              <div class="dialog-object">
                <v-progress-linear
                  :active="activeProgress"
                  color="blue"
                  style="max-height: 2px"
                  v-model="progress"
                ></v-progress-linear>
              </div>
            </v-card-text>
            <v-card-actions class="dialog-buttons">
              <v-btn
                @click="cancelPushBtn"
                depressed
                class="button-dialog-cancel"
                >Отмена</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn @click="savePushBtn" depressed class="button-dialog-accept"
                >Сохранить</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn
          @click="pushSend(true)"
          depressed
          class="button-action"
          v-if="!push.isSend"
          >Отправить
        </v-btn>
        <v-btn
          @click="pushSend(false)"
          depressed
          class="button-cancel"
          v-if="push.isSend"
          >Отмена
        </v-btn>
      </div>
    </div>

    <div class="description-title">
      <div class="title-noImage">
        <span>{{ push.title }}</span>
      </div>
    </div>
    <div class="description-container">
      <div class="description-item">
        <span class="itemTitle"> Описание Push: </span>
        {{ push.description }}
      </div>

      <div class="description-item-image">
        <span class="itemTitle"> Фото: </span>
        <v-img
          height="100px"
          width="150px"
          max-height="100px"
          max-width="150px"
          min-height="100px"
          min-width="150px"
          @click="openImage = true"
          :src="createImageURL(push.photo)"
        >
          <template v-slot:placeholder>
            <v-sheet>
              <v-skeleton-loader
                height="100px"
                width="150px"
                max-height="100px"
                max-width="150px"
                min-height="100px"
                min-width="150px"
                type="image"
              />
            </v-sheet>
          </template>
        </v-img>
      </div>
      <v-dialog max-width="500px" v-model="openImage">
        <v-card max-width="500px">
          <v-img :src="createImageURL(push.photo)"> </v-img>
        </v-card>
      </v-dialog>

      <div class="description-item">
        <span class="itemTitle"> Дата создания: </span>
        {{ push.dateCreated | moment("HH:mm DD.MM.YYYY") }}
      </div>
      <div class="description-item">
        <span class="itemTitle"> Интервал публикации: </span>
        {{ push.startPublicationDate | moment("HH:mm DD.MM.YYYY") }} -
        {{ push.endPublicationDate | moment("HH:mm DD.MM.YYYY") }}
      </div>
    </div>
    <v-divider></v-divider>
    <div class="description-subcontainer">
      <div class="subcontainer-title">
        <span> Фильтрация </span>
      </div>
      <div class="description-item">
        <span class="itemTitle"> Период регистрации пользователя: </span>
        {{ push.regUserFromDate | moment("DD.MM.YYYY") }} -
        {{ push.regUserToDate | moment("DD.MM.YYYY") }}
      </div>

      <div class="description-item">
        <span class="itemTitle"> Тип устройства: </span>
        <span v-if="push.typeDevice == 'ios'">iOS</span>
        <span v-if="push.typeDevice == 'android'">Android</span>
      </div>

      <div class="description-item">
        <span class="itemTitle"> Наличие покупок: </span>
        <span v-if="push.availabilityPurchase == null">Не важно</span>
        <span v-if="push.availabilityPurchase == 'yes'">Есть</span>
        <span v-if="push.availabilityPurchase == 'no'">Нет</span>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="description-subcontainer">
      <div class="subcontainer-title">
        <span> Статистика </span>
      </div>
      <div class="description-item">
        <span class="itemTitle"> Количество отправленных уведомлений: </span>
        {{ push.pushSendedCount }}
      </div>
      <div class="description-item">
        <span class="itemTitle"> Количество доставленных уведомлений: </span>
        {{ push.pushDeliveredCount }}
      </div>
    </div>

    <div class="line gray"></div>
    <v-card class="mb-6 elevation-0">
      <v-card-title> Прикрепить автора </v-card-title>
      <v-row class="ml-1" v-if="!push.isSend">
        <v-col cols="3">
          <v-combobox
            :items="authors"
            @change="authorAddPushEvent"
            @update:search-input="searchAuthorEvent"
            item-text="fullName"
            item-value="authorId"
            label="Поиск по имени автора"
            v-model="searchAuthor"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-data-table
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25],
        }"
        :headers="authorHeaders"
        :items="pushAuthors"
        :items-per-page="authorOptionTable.itemsPerPage"
        :page="authorOptionTable.page"
        :server-items-length="authorOptionTable.serverItemsLength"
        @pagination="authorTableChange"
        class="elevation-0"
      >
        <template v-slot:no-data>
          <v-btn @click="getPushAuthors" depressed class="button-action"
            >Обновить</v-btn
          >
        </template>
        <template v-slot:item.fullName="{ item }">
          {{ item.author.fullName }}
        </template>
        <template v-slot:item.alias="{ item }">
          {{ item.author.alias }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            @click="onDeletePushAuthorClickRow(item)"
            class="mr-2"
            small
            v-if="!push.isSend"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <div class="line gray"></div>
    </v-card>

    <div class="line gray"></div>
    <v-card class="mb-6 elevation-0">
      <v-card-title> Прикрепить курс </v-card-title>
      <v-row class="ml-1" v-if="!push.isSend">
        <v-col cols="3">
          <v-combobox
            :items="courses"
            @change="courseAddPushEvent"
            @update:search-input="searchCourseEvent"
            item-text="name"
            item-value="id"
            label="Поиск по названию курса"
            v-model="searchCourse"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-data-table
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25],
        }"
        :headers="courseHeaders"
        :items="pushCourses"
        :items-per-page="courseOptionTable.itemsPerPage"
        :page="courseOptionTable.page"
        :server-items-length="courseOptionTable.serverItemsLength"
        @pagination="courseTableChange"
        class="elevation-0"
      >
        <template v-slot:no-data>
          <v-btn @click="getPushCourses" depressed class="button-action"
            >Обновить</v-btn
          >
        </template>

        <template v-slot:item.name="{ item }">
          {{ item.course.name }}
        </template>
        <!-- <template v-slot:item.fullName="{ item }">
                    {{item.course.author.fullName}}
                </template> -->
        <template v-slot:item.actions="{ item }">
          <v-icon
            @click="onDeletePushCourseClickRow(item)"
            class="mr-2"
            small
            v-if="!push.isSend"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <div class="line gray"></div>
    </v-card>

    <div class="line gray"></div>
    <v-card class="elevation-0">
      <v-card-title> Прикрепить уроки </v-card-title>
      <v-row class="ml-1" v-if="!push.isSend">
        <v-col cols="3">
          <v-combobox
            :items="authorsForLessson"
            @change="authorForLessonSearchEvent"
            @update:search-input="searchAuthorForLessonEvent"
            item-text="fullName"
            item-value="id"
            label="Ввведите имя автора"
            v-model="authorIdForLesson"
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row class="ml-1" v-if="authorIdForLesson">
        <v-col cols="3">
          <v-combobox
            :items="coursesForLesson"
            @change="courseForLessonSearchEvent"
            item-text="name"
            item-value="courseId"
            label="Ввведите название курса"
            v-model="courseIdForLesson"
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row class="ml-1" v-if="courseIdForLesson">
        <v-col cols="3">
          <v-combobox
            :items="lessons"
            @change="lessonAddPushEvent"
            item-text="name"
            item-value="lessonId"
            label="Ввведите название урока"
            v-model="lessonId"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-data-table
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25],
        }"
        :headers="lessonHeaders"
        :items="pushLessons"
        :items-per-page="lessonOptionTable.itemsPerPage"
        :page="lessonOptionTable.page"
        :server-items-length="lessonOptionTable.serverItemsLength"
        @pagination="lessonTableChange"
        class="elevation-0"
      >
        <template v-slot:no-data>
          <v-btn @click="getPushLessons" depressed class="button-action"
            >Обновить</v-btn
          >
        </template>

        <template v-slot:item.lessonName="{ item }">
          {{ item.lesson.name }}
        </template>
        <template v-slot:item.courseName="{ item }">
          {{ item.course.name }}
        </template>
        <template v-slot:item.fullName="{ item }">
          {{ item.author.fullName }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            @click="onDeletePushLessonClickRow(item)"
            class="mr-2"
            small
            v-if="!push.isSend"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <div class="line gray"></div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "push",
  data: () => ({
    lessonId: null,
    authorId: null,
    dialogPush: false,
    openImage: false,
    snackbar: {
      isOpen: true,
      text: "",
    },
    coursesForLesson: [],
    authorsForLessson: [],
    authorIdForLesson: null,
    pushLessons: [],
    courseHeaders: [
      {
        text: "Название курса",
        align: "left",
        sortable: false,
        value: "name",
      },
      // {
      //     text: 'Автор курса',
      //     align: 'left',
      //     sortable: false,
      //     value: 'fullName',
      // },
      { text: "   ", align: "right", value: "actions", sortable: false },
    ],
    lessonHeaders: [
      {
        text: "Название",
        align: "left",
        sortable: false,
        value: "lessonName",
      },
      {
        text: "Курс",
        align: "left",
        sortable: false,
        value: "courseName",
      },
      {
        text: "Автор",
        align: "left",
        sortable: false,
        value: "fullName",
      },
      { text: "   ", align: "right", value: "actions", sortable: false },
    ],
    courseOptionTable: {
      page: 1,
      itemsPerPage: 5,
      serverItemsLength: -1,
    },
    lessonOptionTable: {
      page: 1,
      itemsPerPage: 5,
      serverItemsLength: -1,
    },
    courseIdForLesson: null,

    authorOptionTable: {
      page: 1,
      itemsPerPage: 5,
      serverItemsLength: -1,
    },
    authors: [],

    authorHeaders: [
      {
        text: "ФИО автора",
        align: "left",
        sortable: false,
        value: "fullName",
      },
      {
        text: "Псевдоним",
        align: "left",
        sortable: false,
        value: "alias",
      },
      {
        text: "",
        align: "right",
        sortable: false,
        value: "actions",
      },
    ],
    indexArrayLesson: [],

    push: {},
    availabilityPurchases: [
      { id: "", name: "Не важно" },
      { id: "yes", name: "Есть" },
      { id: "no", name: "Нет" },
    ],
    typeDevices: [
      { id: "ios", name: "iOS" },
      { id: "android", name: "Android" },
    ],
    pushCopy: {},
    pushStartPublicationDate: null,
    pushEndPublicationDate: null,
    pushRegUserFromDate: null,
    pushRegUserToDate: null,
    pushStartPublicationMenu: false,
    pushEndPublicationMenu: false,
    pushRegUserFromDateMenu: false,
    pushRegUserToDateMenu: false,
    pushRegUserToMenu: false,
    searchAuthor: null,
    searchCourse: null,
    courses: [],
    pushCourses: [],
    pushAuthors: [],
    lessons: [],
    activeProgress: false,
    progress: 0,
  }),
  created() {
    this.getPush();
    this.getPushAuthors();
    this.getPushCourses();
    this.getPushLessons();
  },
  watch: {
    pushStartPublicationDate() {
      this.pushStartPublicationDateFormatted = this.formatDate(
        this.pushStartPublicationDate
      );
    },
    pushEndPublicationDate() {
      this.pushEndPublicationDateFormatted = this.formatDate(
        this.pushEndPublicationDate
      );
    },
    pushRegUserFromDate() {
      this.pushRegUserFromDateFormatted = this.formatDate(
        this.pushRegUserFromDate
      );
    },
    pushRegUserToDate() {
      this.pushRegUserToFormatted = this.formatDate(this.pushRegUserToDate);
    },
  },
  methods: {
    pushSend(isSend) {
      if (!confirm("Уверены?")) {
        return;
      }

      this.snackbar.isOpen = false;
      axios
        .get(this.$store.getters.apiAdminPanelV3 + "/push/send", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
            isSend: isSend,
          },
        })
        .then(() => {
          this.getPush();
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    formatDateV2(date) {
      if (!date) return null;
      date = new Date(date);
      return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
        .getDate()
        .toString()
        .padStart(2, "0")}/${date.getFullYear()}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getPush() {
      this.snackbar.isOpen = false;
      axios
        .get(this.$store.getters.apiAdminPanelV3 + "/push", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.push = res.data;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    openFormPush() {
      this.pushCopy = Object.assign({}, this.push);

      this.pushStartPublicationDateFormatted = this.formatDateV2(
        this.pushCopy.startPublicationDate
      );
      this.pushStartPublicationDate = this.parseDate(
        this.pushStartPublicationDateFormatted
      );

      this.pushEndPublicationDateFormatted = this.formatDateV2(
        this.pushCopy.endPublicationDate
      );
      this.pushEndPublicationDate = this.parseDate(
        this.pushEndPublicationDateFormatted
      );

      this.pushRegUserFromDateFormatted = this.formatDateV2(
        this.pushCopy.regUserFromDate
      );
      this.pushRegUserFromDate = this.parseDate(
        this.pushRegUserFromDateFormatted
      );

      this.pushRegUserToFormatted = this.formatDateV2(
        this.pushCopy.regUserToDate
      );
      this.pushRegUserToDate = this.parseDate(this.pushRegUserToFormatted);
    },
    savePushBtn() {
      let formData = new FormData();
      formData.append("id", this.pushCopy.id);
      formData.append("title", this.pushCopy.title || "");
      formData.append("description", this.pushCopy.description || "");
      formData.append("photo", this.pushCopy.newPhoto || "");
      formData.append("typeDevice", this.pushCopy.typeDevice || "");
      formData.append(
        "availabilityPurchase",
        this.pushCopy.availabilityPurchase || ""
      );
      if (this.pushRegUserFromDate) {
        formData.append(
          "regUserFromDate",
          new Date(this.pushRegUserFromDate).getTime()
        );
      }
      if (this.pushRegUserToDate) {
        formData.append(
          "regUserToDate",
          new Date(this.pushRegUserToDate).getTime()
        );
      }
      if (this.pushStartPublicationDate) {
        formData.append(
          "startPublicationDate",
          new Date(this.pushStartPublicationDate).getTime()
        );
      }
      if (this.pushEndPublicationDate) {
        formData.append(
          "endPublicationDate",
          new Date(this.pushEndPublicationDate).getTime()
        );
      }
      this.activeProgress = true;
      axios
        .post(this.$store.getters.apiAdminPanelV3 + "/push", formData, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            this.progress = percentCompleted;
          },
        })
        .then(() => {
          this.activeProgress = false;
          this.dialogPush = false;
          this.getPush();
          this.pushCopy = {};
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    cancelPushBtn() {
      this.dialogPush = false;
      this.pushCopy = {};
    },
    searchAuthorEvent(data) {
      if (!data) {
        data = "null";
      }

      if (data.length <= 1) {
        data = "null";
      }

      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV3 + "/push/authors/search",
          {
            search: data,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.authors = res.data;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    authorAddPushEvent(data) {
      if (!data.id) {
        return;
      }

      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV3 + "/push/author",
          {
            pushId: this.$route.params.id,
            authorId: data.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.searchAuthor = null;
          this.getPushAuthors();
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },

    authorTableChange(data) {
      this.authorOptionTable.page = data.page;
      this.authorOptionTable.itemsPerPage = data.itemsPerPage;
      this.getPushAuthors();
    },
    getPushAuthors() {
      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV3 + "/push/author/get",
          {
            optionTable: this.authorOptionTable,
            pushId: this.$route.params.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.authorOptionTable = res.data.optionTable;
          this.pushAuthors = res.data.items;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    onDeletePushAuthorClickRow(data) {
      if (!confirm("Удалить?")) {
        return;
      }

      this.snackbar.isOpen = false;
      axios
        .delete(this.$store.getters.apiAdminPanelV3 + "/push/author", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: data.pushAuthorId,
          },
        })
        .then(() => {
          this.getPushAuthors();
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },

    courseAddPushEvent(data) {
      if (!data.id) {
        return;
      }

      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV3 + "/push/course",
          {
            pushId: this.$route.params.id,
            courseId: data.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.searchCourse = null;
          this.getPushCourses();
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },

    searchCourseEvent(data) {
      if (!data) {
        data = "null";
      }

      if (data.length <= 1) {
        data = "null";
      }

      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV3 + "/push/courses/search",
          {
            search: data,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.courses = res.data;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    getPushCourses() {
      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV3 + "/push/course/get",
          {
            optionTable: this.authorOptionTable,
            pushId: this.$route.params.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.authorOptionTable = res.data.optionTable;
          this.pushCourses = res.data.items;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    courseTableChange(data) {
      this.courseOptionTable.page = data.page;
      this.courseOptionTable.itemsPerPage = data.itemsPerPage;
      this.getPushCourses();
    },
    onDeletePushCourseClickRow(data) {
      if (!confirm("Удалить?")) {
        return;
      }

      this.snackbar.isOpen = false;
      axios
        .delete(this.$store.getters.apiAdminPanelV3 + "/push/course", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: data.id,
          },
        })
        .then(() => {
          this.getPushCourses();
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },

    authorForLessonSearchEvent(data) {
      // eslint-disable-next-line

      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV3 + "/push/courses/search",
          {
            id: data.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.coursesForLesson = res.data;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    searchAuthorForLessonEvent(data) {
      if (!data) {
        data = "null";
      }

      if (data.length <= 1) {
        data = "null";
      }

      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV3 + "/push/authors/search",
          {
            search: data,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.authorsForLessson = res.data;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },

    courseForLessonSearchEvent(data) {
      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV3 + "/push/lesson/search",
          {
            id: data.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.lessons = res.data;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    lessonAddPushEvent(data) {
      if (!data.id) {
        return;
      }

      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV3 + "/push/lesson",
          {
            pushId: this.$route.params.id,
            lessonId: data.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.lessonId = null;
          this.getPushLessons();
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    lessonTableChange(data) {
      this.lessonOptionTable.page = data.page;
      this.lessonOptionTable.itemsPerPage = data.itemsPerPage;
      this.getPushLessons();
    },
    getPushLessons() {
      this.snackbar.isOpen = false;
      axios
        .post(
          this.$store.getters.apiAdminPanelV3 + "/push/lessons/get",
          {
            optionTable: this.authorOptionTable,
            pushId: this.$route.params.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.authorOptionTable = res.data.optionTable;
          this.pushLessons = res.data.items;
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    onDeletePushLessonClickRow(data) {
      if (!confirm("Удалить?")) {
        return;
      }

      this.snackbar.isOpen = false;
      axios
        .delete(this.$store.getters.apiAdminPanelV3 + "/push/lesson", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: data.id,
          },
        })
        .then(() => {
          this.getPushLessons();
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
  },
};
</script>
