import { render, staticRenderFns } from "./SuccessAlert.vue?vue&type=template&id=08966576&scoped=true"
import script from "./SuccessAlert.vue?vue&type=script&lang=js"
export * from "./SuccessAlert.vue?vue&type=script&lang=js"
import style0 from "./SuccessAlert.vue?vue&type=style&index=0&id=08966576&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08966576",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
installComponents(component, {VAlert})
